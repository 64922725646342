import React, { useContext, useEffect, useRef, useState } from "react";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import AdminSidebar from "../main/adminSidebar";
import axios from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { StudentDataContext } from "../context/datacontext";
import SidebarStudent from "../main/sidebarStudent";
import * as LEVEL_CONST from "../main/constants";
import file1 from "../../assets/pdf/pdf_Individual.pdf";
import terilogo from "../../assets/icons/terilogo.png";
import olympiadLogo from "../../assets/icons/olympiadLogo.jpg";

function StudentExamDetails() {
  const { state, dispatch } = useContext(StudentDataContext);

  const [rollNo, setRoll_no] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [levelOfExam, setLevelOfExam] = useState("");
  const [theme, setTheme] = useState("");
  const [examTime, setExamTime] = useState("");
  const [mockExamTime, setMockExamTime] = useState("");
  const [msg, setMsg] = useState("");
  const [amount, setAmount] = useState(0);
  const [showMsg, setShowMsg] = useState("");
  const printRef = useRef();

  useEffect(() => {
    const getExamDetails = async () => {
      const esdExamDetails = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.indvExamDetails}`,
        {
          params: {
            roll_no: `${state?.roll_no}`,
          },
        }
      );
      if (esdExamDetails?.status === 200) {
        let outputTime = "";
        let outputTime1 = "";
        console.log("esdExamDetails", esdExamDetails);
        if (esdExamDetails["data"]["data"][0].length === 0) {
          setShowMsg(
            "You have not paid the fees.Hence exam details are not available."
          );
        } else {
          let result = esdExamDetails["data"]["data"][0][0];
          let levelvalue = result.ExamLevel.trim();
          let themevalue = result.ExamTheme.trim();
          setRoll_no(result.RollNo);
          setPassword(`GO-${result.Password}`);
          setName(result.Name);
          let level = LEVEL_CONST.LEVEL_MAPPER[themevalue][levelvalue];
          setLevelOfExam(level);
          //   setLevelOfExam(levelvalue);
          setTheme(themevalue);
          setExamTime(changeDateFormat(result.ExamSlotDateTime, outputTime));
          setMockExamTime(
            changeDateFormat(result.DemoSlotDateTime, outputTime1)
          );
        }
      }
    };
    getExamDetails();
  }, []);

  const downloadPdf = async () => {
    // const input = document.getElementById('mainContent');
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("examDetails.pdf");
  };

  const changeDateFormat = (inputTime, outputTime) => {
    if (inputTime !== null) {
      let timeval = inputTime.split("-");
      outputTime +=
        timeval[2] + "-" + timeval[1] + "-" + timeval[0] + "-" + timeval[3];
      return outputTime;
    } else {
      return "";
    }
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-3">
          {/* side bar will come here */}
          <SidebarStudent />
        </div>

        <div className={`col-lg-9 ${showMsg !== "" ? "" : "hideRow"}`}>
          <h2 className="msgAlignCenter">{showMsg}</h2>
        </div>

        <div className={`col-lg-9 ${showMsg !== "" ? "hideRow" : ""}`}>
          <div
            className="section-title mb-4 text-muted"
            style={{ marginTop: "5em" }}
          >
            <h6 className="font-bold ">Exam Details</h6>
            {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
          </div>

          <div>
            <div className="btnmain examDetailsPdf">
              <a href={file1} target="_blank">
                {" "}
                <button className={`btn btn-primary`}>
                  Download Instructions for Examination Portal
                </button>
              </a>

              <a>
                {" "}
                <button className={`btn btn-primary`} onClick={downloadPdf}>
                  Download Exam Details
                </button>
              </a>
            </div>
          </div>

          <main className="content" id="mainContent" ref={printRef}>
            <div class="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
              <div
                className="section-title mb-4 text-muted"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  {/* <h6 className="font-bold ">ESD Data</h6> */}
                  {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                  <a className="navbar-brand reportLogo">
                    {" "}
                    <img src={terilogo} width="190" height="50" alt="logo" />
                  </a>
                </div>

                <div>
                  <a className="navbar-brand">
                    {" "}
                    <img
                      src={olympiadLogo}
                      width="190"
                      height="30"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div class="shadow bg-white rounded-16">
                <div class="p-4 ">
                  <div class="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>User Name (Login Id)</label>
                        <input
                          type="text"
                          style={{ fontWeight: "bold" }}
                          placeholder=""
                          value={rollNo}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Password</label>
                        <input
                          type="text"
                          style={{ fontWeight: "bold" }}
                          placeholder=""
                          value={password}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Name</label>
                        <input
                          type="text"
                          style={{ fontWeight: "bold" }}
                          placeholder=""
                          value={name}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Subject</label>
                        <input
                          type="text"
                          style={{ fontWeight: "bold" }}
                          placeholder=""
                          value={levelOfExam}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Theme of Exam</label>
                        <input
                          type="text"
                          style={{ fontWeight: "bold" }}
                          placeholder=""
                          value={theme}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Theme Exam Date Time*</label>
                        <input
                          type="text"
                          style={{ fontWeight: "bold" }}
                          placeholder=""
                          value={examTime}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Mock Exam Date Time*</label>
                        <input
                          type="text"
                          style={{ fontWeight: "bold" }}
                          placeholder=""
                          value={mockExamTime}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default StudentExamDetails;
