import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import studentimg from "../../assets/icons/login.png";
import { Colors } from "../../assets/css/color";
import { useNavigate } from "react-router";
import SidebarStudent from "../main/sidebarStudent";
import axios from "axios";
import {
  API_BASE_JAVA_URL,
  API_BASE_URL,
  API_END_POINTS,
} from "../../apis/api";
import { StudentDataContext } from "../context/datacontext";
import SidebarIn from "../main/SidebarIn";
import { formatDate } from "../../helper/helper";

export default function StudentInPayment() {
  useEffect(() => {
    getPaymentDetails();
  }, []);
  const [err, setErr] = useState("");
  const { state, dispatch } = useContext(StudentDataContext);
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState({});
  const [utrValidateMsg, setUtrValidateMsg] = useState("");
  const [utr, setutr] = useState(0);
  const [currencyIcon, setCurrencyIcon] = useState();
  const [paymentstatus, setpaymentStatus] = useState("");
  const [paymentAllowedMsg, setPaymentAllowedMsg] = useState("");
  const [ispaymentAllowed, setIspaymentAllowed] = useState(false);

  const makePayment = async () => {
    if (state?.student?.country !== "India") {
      if (utr === "" || utr === 0) {
        setUtrValidateMsg("Please enter UTR Number");
        return;
      } else {
        setUtrValidateMsg("");
      }

      let obj = {
        amount: paymentData?.totalFees,
        idval: state.roll_no,
      };

      const payment = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.internationalCashPayment}`,
        obj
      );

      let paymentinsertrecordsObj = {
        schoolcode_Rollno: state?.roll_no,
        mode: "ONLINE",
        subscriberType: "INDV",
        paymentId: utr,
        paymentReceivedStatus: "pending",
        createdBy: state.roll_no,
        modifyBy: state.roll_no,
        ...payment.data.data,
      };

      const paymentinsertrecords = await axios.post(
        `${API_BASE_JAVA_URL}${API_END_POINTS.insertPaymentDetails}`,
        paymentinsertrecordsObj
      );
      navigate("/student-Inapplication-status");
      console.log("paymentinsertrecords", paymentinsertrecords);
      if (paymentinsertrecords?.status === 200) {
        navigate("/student-application-status");
      }
    } else {
      let obj = {
        amount: paymentData?.totalFees,
        type: "INDV",
        idval: state.roll_no,
        email: state?.student.email,
        phone: "",
        name: state?.student.name,
        productinfo: state.roll_no,
      };

      if (state?.student?.country !== "India") {
        obj.phone = state?.student.mobile;
      } else {
        obj.phone = "9818542645";
      }

      const payment = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.payment}`,
        obj
      );
      //const payment = await axios.get(`${API_END_POINTS.payment}`);

      if (payment?.data?.status === 200) {
        // navigate("/school-slot");
        // console.log("payment", payment);
        navigate("/student-Inapplication-status");
        // student-application-status
        window.open(payment.data.url, "_blank");
        let paymentinsertrecordsObj = {
          schoolcode_Rollno: state?.roll_no,
          mode: "ONLINE",
          subscriberType: "INDV",
          paymentId: "",
          paymentReceivedStatus: "pending",
          createdBy: state.roll_no,
          modifyBy: state.roll_no,
          ...payment.data.data,
        };

        const paymentinsertrecords = await axios.post(
          `${API_BASE_JAVA_URL}${API_END_POINTS.insertPaymentDetails}`,
          paymentinsertrecordsObj
        );

        console.log("paymentinsertrecords", paymentinsertrecords);
      } else {
        setErr(payment?.data?.data?.data);
      }
    }
  };

  const getPaymentDetails = async () => {
    const getpayment = await axios.get(
      `${API_BASE_JAVA_URL}${API_END_POINTS.getPaymentDetailsForIndividualStudent}`,
      {
        params: {
          rollNumber: state.roll_no,
        },
      }
    );

    if (getpayment?.status === 200) {
      let currencyval = state?.student?.country === "India" ? "INR" : "$";
      setCurrencyIcon(currencyval);
      setPaymentData(getpayment.data);

      if (getpayment.data.examTheme === "" && getpayment.data.demoExam === "") {
        setPaymentAllowedMsg(
          "Kindly choose your examtheme or mock test  first before doing payment"
        );
        setpaymentStatus("paid");
        return;
      }

      if (
        getpayment?.data?.totalFees === 0 ||
        (getpayment?.data?.demoExam === "YES" &&
          !getpayment?.data?.demoSlotDateTime)
      ) {
        setIspaymentAllowed(false);
      } else {
        setIspaymentAllowed(true);
      }

      if (getpayment.data.paymentStatus === 0) {
        setpaymentStatus("unpaid");
      } else {
        setPaymentAllowedMsg("Payment is already done");
        setpaymentStatus("paid");
      }
      const {
        data: { demoSlotDateTime, examSlotDateTime },
      } = getpayment;
      console.log("demoSlotDateTime", demoSlotDateTime);
      console.log("examSlotDateTime", examSlotDateTime);
      dispatch({
        type: "ADD_STUDENT_INFO",
        student: {
          ...state.student,
          demoSlotDateTime,
          examSlotDateTime,
        },
      });
    }

    console.log("getpayment", getpayment);
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-3">
          {/* side bar will come here */}
          <SidebarIn />
        </div>
        <div className="col-lg-9 ">
          {/* {ispaymentAllowed && state?.student?.country !== 'India' && (
            <div class="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5" style={{ color: 'red' }}>
              International payments are not available. Please check again after some time.
            </div>
          )} */}

          {ispaymentAllowed && (
            <div class="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
              <div class="section-title mb-4 text-muted">
                <h6 class="font-bold ">Submit Registration Fees</h6>
                {/* <p>Pay after click make payment button</p> */}
              </div>

              <div class="shadow bg-white rounded-16">
                <div class="p-4 ">
                  <div class="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Login Id</label>
                        <input
                          type="text"
                          placeholder=""
                          value={paymentData?.rollNo}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>

                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Name Of Student</label>
                        <input
                          type="text"
                          placeholder=""
                          value={paymentData?.name}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    {/* <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Level Of Exam</label>
                        <input type="text" placeholder="" value={paymentData?.examLevel} disabled name="city" required="" />
                      </div>
                    </div> */}

                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Topic of exam</label>
                        {/* <input type="text" placeholder=""  value={paymentData?.examTheme} disabled name="city" required="" /> */}
                        <input
                          type="text"
                          placeholder=""
                          GO4U
                          value={"GO4Youth"}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    {/* <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Mock Test</label>
                        <input
                          type="text"
                          placeholder=""
                          value={paymentData?.demoExam}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div> */}

                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Fees to be paid</label>
                        <input
                          type="text"
                          placeholder=""
                          value={`${currencyIcon} ${paymentData?.totalFees}`}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>Exam Slot Time</label>
                        <input
                          type="text"
                          placeholder=""
                          // value={`${paymentData?.examSlotDateTime}`}
                          value={`${
                            !paymentData?.examSlotDateTime
                              ? ""
                              : formatDate(paymentData?.examSlotDateTime)
                          }`}
                          disabled
                          name="city"
                          required=""
                        />
                      </div>
                    </div>

                    {state?.student?.demoExam === "YES" &&
                      paymentData?.demoSlotDateTime && (
                        <div class="col-sm">
                          <div class="form-wrapper">
                            <label>Mock Slot Time</label>
                            <input
                              type="text"
                              placeholder=""
                              value={`${
                                state?.student?.demoExam === "YES"
                                  ? formatDate(paymentData?.demoSlotDateTime)
                                  : ""
                              }`}
                              disabled
                              name="city"
                              required=""
                            />
                          </div>
                        </div>
                      )}
                  </div>

                  {state?.student?.country !== "India" && err.length === 0 && (
                    <div className="paymentErr">
                      <div style={{ textAlign: "center", color: "red" }}>
                        <h2>
                          All international schools / individual students are
                          advised to pay the aforementioned registration fees
                          via bank transfer (NEFT / RTGS) to the following bank
                          account.
                        </h2>
                        <h3>
                          Confirmation of registration will be updated on the
                          portal, once we reconcile the payment from the bank.
                          Your payment will be updated in 5-7 days
                        </h3>
                      </div>

                      <div className="p-4 ">
                        <div className="row paymentTable">
                          <div className="col">
                            <div className="form-wrapper accInf">
                              <div className="accInfSubDiv">
                                <h3>Name of the A/c Holder</h3>
                              </div>
                              <div className="accInfSubDiv">
                                <h3>The Energy and Resources Institute</h3>
                              </div>
                            </div>
                            <hr />
                            <div className="form-wrapper accInf">
                              <div className="accInfSubDiv">
                                <h3>Account Number</h3>
                              </div>
                              <div className="accInfSubDiv">
                                <h3>001694600001134</h3>
                              </div>
                            </div>
                            <hr />
                            <div className="form-wrapper accInf">
                              <div className="accInfSubDiv">
                                <h3>Name of the Bank</h3>
                              </div>
                              <div className="accInfSubDiv">
                                <h3>Yes Bank</h3>
                              </div>
                            </div>
                            <hr />
                            <div className="form-wrapper accInf">
                              <div className="accInfSubDiv">
                                <h3>Full address of the bank</h3>
                              </div>
                              <div className="accInfSubDiv">
                                <h3>
                                  D-12,South Extension, Part 2 New Delhi-110049,
                                  India
                                </h3>
                              </div>
                            </div>
                            <hr />
                            <div className="form-wrapper accInf">
                              <div className="accInfSubDiv">
                                <h3>Swift Code</h3>
                              </div>
                              <div className="accInfSubDiv">
                                <h3>YESBINBBDEL</h3>
                              </div>
                            </div>
                            <hr />
                            <div className="form-wrapper accInf">
                              <div className="accInfSubDiv">
                                <h3>IFSC Code:</h3>
                              </div>
                              <div className="accInfSubDiv">
                                <h3>YESB0000016</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {state?.student?.country !== "India" && (
                    <div className="form-wrapper ">
                      {/* <input type="number" placeholder="Pin code" required="" /> */}
                      <label>
                        UTR Number:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="UTR Number"
                        name="UTR"
                        // required
                        onChange={(utrValue) => {
                          if (utrValue.target.value === "") {
                            setUtrValidateMsg("Please enter UTR Number");
                          } else {
                            setUtrValidateMsg("");
                            setutr(utrValue.target.value);
                          }
                        }}
                      />
                    </div>
                  )}
                  <div>
                    <h5 style={{ color: "red" }}>{utrValidateMsg}</h5>
                  </div>

                  <div className="row my-3">
                    <div className="text-center">
                      <button
                        class={`btn btn-primary mx-auto ${
                          paymentstatus === "paid" ? "paidcls" : "unpaidcls"
                        }`}
                        onClick={makePayment}
                      >
                        Make Payment
                      </button>
                      {/* <button className={({ 'paidcls': paymentstatus === 'paid', 'unpaidcls': paymentstatus !== 'paid', 'btn': true, 'btn-primary': true, 'mx-auto': true })} onClick={makePayment}>Make Payment</button> */}
                    </div>
                    <div>
                      <h2>{paymentAllowedMsg}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!ispaymentAllowed && (
            <div class="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
              <h2>Kindly select slots before doing the payment</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
