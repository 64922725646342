import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { StudentDataContext } from "../context/datacontext";
import Sidebar from "../main/sidebar";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";

function NameChange() {
  const { state, dispatch } = useContext(StudentDataContext);
  const [rollNo, setRollNo] = useState("");
  const [oldName, setOldName] = useState("");
  const [newName, setNewName] = useState("");
  const [rollNoError, setRollNoError] = useState("");
  const [errordisp, setErrordisp] = useState("");
  //   const [issubmitAllowed, setIsSubmitAllowed] = useState(true);
  const searchRollNo = async () => {
    setErrordisp("");
    setNewName("");
    if (!rollNo) {
      setRollNoError("Enter roll no to search from records");
      setOldName("");
    } else {
      const studentData = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.getName}`,
        {
          params: {
            roll_no: `${rollNo}`,
            type: "School",
          },
        }
      );
      console.log("studentData", studentData);
      if (studentData?.status && studentData?.data) {
        // if (studentData?.data?.data?.NewValue) {
        //   setNewName(studentData?.data?.data.NewValue);
        // }
        if (studentData?.data?.data?.Name) {
          setOldName(studentData?.data?.data?.Name);
          setRollNoError("");
        } else {
          setOldName("");
          setRollNoError("There is no record for this roll no");
        }
        setNewName("");
      }
    }
  };

  const updateName = async () => {
    if (!oldName || !newName) {
      setErrordisp("Name cannot be updated");
    } else {
      setErrordisp("");
      const studentData = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.updateName}`,
        { oldName, newName, roll_no: rollNo, type: "School" }
      );
      console.log("studentData", studentData);
      if (studentData?.status && studentData?.data) {
        if (studentData?.data?.data?.length > 0) {
          setErrordisp(`${studentData?.data?.data[0]["@msg"]}`);
          //   setIsSubmitAllowed(false);
        }
      }
    }
    setOldName("");
    setNewName("");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-3">
            {/* side bar will come here */}
            <Sidebar />
          </div>
          <div className="col-lg-9 ">
            <div className="application-status p-5 pb-50-xs">
              <div className="page-heading">
                <h4>
                  <b>Certificate Edit:</b>
                </h4>
                {/* <p>Check Payment details</p> */}
              </div>
              <div class="shadow mb-5 rounded-16">
                <div class="p-4">
                  <div className="row">
                    <div className="col-sm">
                      <div className="form-wrapper">
                        <label>
                          <b>Roll No:</b>
                        </label>
                        <input
                          type="text"
                          // placeholder={decoded?.schoolname}
                          value={rollNo}
                          name="roll"
                          required
                          onChange={(e, postalAddress) => {
                            setRollNo(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="text-center">
                      <button
                        className="btn btn-primary mx-auto"
                        style={{ width: "15rem" }}
                        onClick={searchRollNo}
                      >
                        <b>Search</b>
                      </button>
                    </div>
                    <div>
                      <h2 style={{ textAlign: "center", color: "red" }}>
                        {rollNoError}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div class="shadow mb-5 rounded-16">
                <div class="p-4">
                  <div class="form-wrapper">
                    <label>
                      <b>Old Name:</b>{" "}
                    </label>
                    <input
                      type="text"
                      name="oldName"
                      disabled
                      value={oldName}
                      id=""
                    />
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>
                          <b>New Name:</b>
                        </label>
                        <input
                          type="text"
                          name="newName"
                          id=""
                          value={newName}
                          onChange={(password) =>
                            setNewName(password.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 mb-3">
                    <div class="d-flex justify-content-center">
                      <button
                        class="btn btn-primary "
                        onClick={updateName}
                        style={{ width: "15rem" }}
                      >
                        <b>Update</b>
                      </button>
                    </div>
                    <div>
                      <h2 style={{ textAlign: "center", color: "red" }}>
                        {errordisp}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Name cannot be updated more than once for a given roll
                      number !!!!
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NameChange;
