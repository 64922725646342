import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import Sidebar from "../main/sidebar";
import { StudentDataContext } from "../context/datacontext";
import "./SchoolApplicationStatus.scss";

export default function MaterialDetails() {
  const { state } = useContext(StudentDataContext);
  const [materialDetails, setMaterialDetails] = useState([]);
  const [sampleBooklet, setSampleBooklet] = useState({ type: "", data: [] });
  // const [mainExam, setMainExam] = useState([]);

  const getMaterialData = async () => {
    const appMaterial = await axios.get(
      `${API_BASE_URL}${API_END_POINTS.getMatDetails}`,
      {
        params: {
          school_code: state.school_code,
        },
      }
    );

    if (appMaterial?.status && appMaterial?.data) {
      const {
        data: { data: appMaterialData },
      } = appMaterial;
      console.log("appMaterialData", appMaterialData);

      // const sampleBooklet = appMaterialData.filter(
      //   (data) => data.mattype === "SAMPLE BOOKLET"
      // );
      // setSampleBooklet([...sampleBooklet]);

      // const mainExam = appMaterialData.filter(
      //   (data) => data.mattype === "CERTIFICATE"
      // );
      // setMainExam([...mainExam]);

      // console.log("sampleBooklet", sampleBooklet);
      // console.log("mainExam", mainExam);
      // setMaterialDetails([...appMaterialData]);
      filterType(appMaterialData);
    }
  };

  const filterType = (appMaterialData) => {
    if (
      appMaterialData.filter((data) => data.mattype === "SAMPLE BOOKLET")
        .length > 0
    ) {
      setSampleBooklet({
        type: "SAMPLE BOOKLET",
        data: appMaterialData.filter(
          (data) => data.mattype === "SAMPLE BOOKLET"
        ),
      });
    } else if (
      appMaterialData.filter((data) => data.mattype === "MAIN EXAM").length > 0
    ) {
      setSampleBooklet({
        type: "MAIN EXAM",
        data: appMaterialData.filter((data) => data.mattype === "MAIN EXAM"),
      });
    } else if (
      appMaterialData.filter((data) => data.mattype === "CERTIFICATE").length >
      0
    ) {
      setSampleBooklet({
        type: "CERTIFICATE",
        data: appMaterialData.filter((data) => data.mattype === "CERTIFICATE"),
      });
    }
  };

  useEffect(() => {
    getMaterialData();
  }, []);

  return (
    <div className="row">
      <div className="col-lg-3">
        <Sidebar mode={state?.mode} />
      </div>
      <div className="col-lg-9 justify-content-center d-flex">
        <div className="application-status p-5 pb-50-xs w-75">
          <div className="page-heading">
            <h4>Material Details</h4>
          </div>

          <div className="shadow-lg p-4 bg-body rounded mb-2">
            <div className="table-responsive-md">
              <table className="table table-striped table-bordered materialTable">
                <thead>
                  <tr>
                    <th>Material Type</th>
                    <th></th>
                    <th>{sampleBooklet?.type}</th>
                  </tr>

                  <tr>
                    <th>Box No</th>
                    <th>Seq No.</th>
                    <th>Tracking Number</th>
                    {/*                   
                    <th>MatType</th>
                    <th>SeqNo</th>
                   
                    <th>BoxNo</th>
                    <th>BarCode</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sampleBooklet?.data.map((detail, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{detail.seqno}</td>

                        <td>{detail.barcode}</td>
                        {/* <td>{detail.mattype}</td>
                        <td>{detail.seqno}</td>
                        <td>{detail.boxno}</td>
                        <td>{detail.barcode}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="shadow-lg p-4 bg-body rounded mb-2">
            <div className="table-responsive-md">
              <table className="table table-striped table-bordered materialTable">
                <thead>
                  <tr>
                    <th>Material Type</th>
                    <th></th>
                    <th>Main Exam</th>
                  </tr>

                  <tr>
                    <th>Box No</th>
                    <th>Seq No.</th>
                    <th>Tracking Number</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {mainExam.map((detail, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{detail.seqno}</td>

                        <td>{detail.barcode}</td>
                       
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div> */}
          <div>
            <h3 style={{ fontWeight: "bold" }}>
              <a
                href="https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx"
                target="_blank"
              >
                Click here to open Speed Post website to track your Box with
                above Tracking Numbers.
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
