import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../main/sidebar";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import "./SchoolApplicationStatus.scss";
import axios from "axios";
import { StudentDataContext } from "../context/datacontext";
import ExportAdminCSV from "../admin/adminExcelDownload";
const dayjs = require("dayjs");

export default function SchoolApplicationStatus() {
  const { state } = useContext(StudentDataContext);
  const [appStatus, setAppStatus] = useState([]);
  const cityStateList = [{ id: 1, value: "PAID" }];
  const [appExceldata, setAppExceldata] = useState([]);
  const headers = [
    {
      SNO: "SNO",
      RollNo: "RollNo",
      Name: "Name",
      Class: "Class",
      Section: "Section",
      // Exam: "Exam",
      ExamDate: "ExamDate",
      // SampleBooklet: "SampleBooklet",
      Fees: "Fees",
      FeesStatus: "FeesStatus",
      Marks: "Marks",
      Result: "Result",
    },
  ];

  const convertFee = (status) => {
    let fee = "";
    fee += state?.country === "India" ? `INR ${status.Fee}` : `$ ${status.Fee}`;
    return fee;
  };

  const getAppStatus = async () => {
    const appStatus = await axios.post(
      `${API_BASE_URL}${API_END_POINTS.applicationStatus}`,
      {
        school_code: state.school_code,
      }
    );

    if (appStatus?.status && appStatus?.data) {
      let appStatusData = modifySortedData(appStatus?.data.data);

      const arr = [];
      appStatusData.map((appStatus, index) => {
        console.log("index", index);
        arr.push({
          SNO: index + 1,
          Name: appStatus.Name,
          Class: appStatus.Class,
          Section: appStatus.Section,
          // Exam: appStatus.ExamTheme,
          // Language: appStatus.Language,
          ExamDate: appStatus.ExamSlotDateTime,
          // SampleBooklet: appStatus.DemoExam,
          RollNo: appStatus.Rollno || "",
          Fees: convertFee(appStatus),
          FeesStatus: appStatus.PaymentStatus === 1 ? "PAID" : "UNPAID",
          Marks: appStatus.Marks,
          Result: appStatus.Result,
        });
      });
      setAppExceldata(arr);
      setAppStatus(appStatusData);
    }
  };

  const modifySortedData = (inputArr) => {
    for (let i = 0; i < inputArr.length; i++) {
      let outputTime = "";
      let outputTime1 = "";
      inputArr[i]["ExamSlotDateTime"] = changeDateFormat(
        inputArr[i]["ExamSlotDateTime"],
        outputTime
      );
      inputArr[i]["DemoSlotDateTime"] = changeDateFormat(
        inputArr[i]["DemoSlotDateTime"],
        outputTime1
      );
    }
    return inputArr;
  };

  const changeDateFormat = (inputTime, outputTime) => {
    if (inputTime !== null) {
      let timeval = inputTime.split("-");
      outputTime += timeval[2] + "-" + timeval[1] + "-" + timeval[0];
      return outputTime;
    } else {
      return "";
    }
  };

  useEffect(() => {
    getAppStatus();
  }, []);

  return (
    <div className="row ">
      <div className="col-lg-3">
        <Sidebar mode={state?.mode} />
      </div>

      <div className="col-lg-9 ">
        <div className="application-status p-5 pb-50-xs">
          <div className="page-heading">
            <h4>Application Status</h4>

            {/* <p>Check Payment details</p> */}
            <div className="row">
              <div className="col-sm text-left">
                <ExportAdminCSV
                  csvData={appExceldata}
                  fileName="Application_status"
                  type="applicationStatus"
                  Heading={headers}
                  buttonName="Download Application Status"
                />
              </div>
              <div className="col-sm">
                {/* <div className="form-wrapper">
                  <label>
                    Fee Status:<span style={{ color: "red" }}>*</span>
                  </label>
                  <select className="dropdown" id="cars">
                    <option value="volvo">Select Country</option>
                  </select>
                </div> */}
              </div>
            </div>
          </div>

          <div className="shadow-lg p-4 bg-body rounded">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>S.NO.</th>
                    <th>Roll no.</th>
                    <th>Name</th>
                    {/* <th>DOB</th> */}
                    <th>Class</th>
                    <th>Section</th>
                    {/* <th>Level</th> */}
                    {/* <th>Exam</th>
                    <th>Language</th> */}
                    <th>ExamDate</th>
                    {/* <th>Mock Test</th> */}
                    {/* <th>Sample Question Booklet</th> */}

                    <th>Fees</th>
                    <th>Fees Status</th>
                    <th>Marks</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {appStatus.map((status, i) => {
                    console.log("status", status);
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{status.Rollno === 0 ? 0 : status.Rollno}</td>
                        <td>{status.Name}</td>
                        {/* <td>{status.DOB}</td> */}

                        <td>{status.Class}</td>
                        <td>{status.Section}</td>
                        {/* <td>{status.ExamTheme}</td>
                        <td>{status.Language}</td> */}
                        <td>{status.ExamSlotDateTime}</td>
                        {/* <td>{status.DemoExam}</td> */}
                        {/* <td>{status.DemoSlotDateTime}</td> */}

                        <td>
                          {state?.country === "India" ? "INR" : "$"}{" "}
                          {status.Fee}
                        </td>
                        <td
                          className={
                            status.PaymentStatus === 1 ? "paid" : "unpaid"
                          }
                        >
                          {status.PaymentStatus === 1 ? "PAID" : "UNPAID"}
                        </td>
                        <td>{status.Marks}</td>
                        <td>{status.Result}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
