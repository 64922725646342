import React, { useEffect, useState } from "react";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import AdminSidebar from "../main/adminSidebar";
import axios from "axios";

function Cash({ isAdmin }) {
  const [roll_no, setRoll_no] = useState("");
  const [proc1, setProc1] = useState([]);
  const [proc2, setProc2] = useState([]);
  const [proc3, setProc3] = useState([]);
  const [schoolcode_Rollno, setSchoolcode_Rollno] = useState("");
  const [subscriberType, setSubscriberType] = useState("");
  const [msg, setMsg] = useState("");
  const [amount, setAmount] = useState(0);

  const getData = async () => {
    const promise1 = axios.get(
      `${API_BASE_URL}${API_END_POINTS.callStoredProc1}`,
      {
        params: {
          roll_no: `${roll_no}`,
        },
      }
    );

    const promise2 = axios.get(
      `${API_BASE_URL}${API_END_POINTS.callStoredProc2}`,
      {
        params: {
          roll_no: `${roll_no}`,
        },
      }
    );

    const promise3 = axios.get(
      `${API_BASE_URL}${API_END_POINTS.callStoredProc3}`,
      {
        params: {
          roll_no: `${roll_no}`,
        },
      }
    );

    const promise4 = axios.get(
      `${API_BASE_URL}${API_END_POINTS.callStoredProc4}`,
      {
        params: {
          roll_no: `${roll_no}`,
        },
      }
    );

    Promise.all([promise1, promise2, promise3, promise4]).then(function (
      values
    ) {
      console.log(values);
      setProc1(values[0]["data"]["data"]);
      setProc2(values[1]["data"]["data"]);
      setProc3(values[2]["data"]["data"]);
      if (values[3]["data"]["data"].length === 0) {
        setMsg("There is no pending fees for the selected school");
        setSchoolcode_Rollno(values[0]["data"]["data"][0]["schoolsCode"]);
        if (values[0]["data"]["data"][0]["SubscriberType"] === "School") {
          setSubscriberType("School");
        } else {
          setSubscriberType("INDV");
        }
      } else {
        setMsg("");
        setAmount(values[3]["data"]["data"][0]["Fee"]);
        setSchoolcode_Rollno(values[0]["data"]["data"][0]["schoolsCode"]);
        if (values[0]["data"]["data"][0]["SubscriberType"] === "School") {
          setSubscriberType("School");
        } else {
          setSubscriberType("INDV");
        }
      }
    });
  };

  const insertPayment = async () => {
    const promise1 = await axios.post(
      `${API_BASE_URL}${API_END_POINTS.insertCashInfo}`,
      {
        amount,
        Schoolcode_Rollno: schoolcode_Rollno,
        SubscriberType: subscriberType,
      }
    );
    console.log("promise1", promise1);
    await getData();
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-3">
          {/* side bar will come here */}
          <AdminSidebar isAdmin={isAdmin} />
        </div>
        <div className="col-lg-9 ">
          <main className="content ">
            <div className="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
              <div className="section-title mb-4 text-muted">
                <h6 className="font-bold ">Cash Receipt</h6>
                {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <div className="form-wrapper">
                  <label>School Code/Roll No</label>
                  {/* <input type="text" disabled="" placeholder="abcd school" name="schname" required="" /> */}
                  <input
                    type="text"
                    name="schoolcode"
                    value={roll_no}
                    onChange={(e) => {
                      setRoll_no(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-sm">
                <div className="btnmain">
                  <a>
                    {" "}
                    <button className={`btn btn-primary`} onClick={getData}>
                      Search
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="shadow bg-white p-3 rounded-16">
              <div className="table-responsive ">
                <div className="section-title mb-4 text-muted">
                  <h6 className="font-bold ">Registration Details</h6>
                  {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                </div>

                <table className="table table-bordered table-accent">
                  <thead>
                    <tr>
                      <th>School Code / Roll No</th>
                      <th>Subscriber-type</th>
                      <th>Name</th>
                      <th>password</th>
                      <th>mobile</th>
                      <th>email</th>
                      <th>county</th>
                      <th>state</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proc1.map((row, i) => {
                      return (
                        <>
                          <tr>
                            <td>{row.schoolsCode}</td>
                            <td>{row.SubscriberType}</td>
                            <td>{row.Name}</td>
                            <td>{row.password}</td>
                            <td>{row.mobile}</td>
                            <td>{row.email}</td>
                            <td>{row.country}</td>
                            <td>{row.state}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="shadow bg-white p-3 rounded-16">
              <div className="table-responsive ">
                <div className="section-title mb-4 text-muted">
                  <h6 className="font-bold ">Fees Details</h6>
                  {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                </div>
                <table className="table table-bordered table-accent">
                  <thead>
                    <tr>
                      <th>ExamTheme</th>
                      <th>Demo Exam</th>
                      <th>Name</th>
                      <th>DOB</th>
                      <th>Fees(Calculated by system)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proc2.map((row, i) => {
                      return (
                        <>
                          <tr>
                            <td>{row.ExamTheme}</td>
                            <td>{row.DemoExam}</td>
                            <td>{row.Name}</td>
                            <td>{row.DOB}</td>
                            <td>{row.Fees}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="shadow bg-white p-3 rounded-16">
              <div className="table-responsive ">
                <div className="section-title mb-4 text-muted">
                  <h6 className="font-bold ">Payment Details</h6>
                  {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                </div>
                <table className="table table-bordered table-accent">
                  <thead>
                    <tr>
                      <th>Schoolcode_Rollno</th>
                      <th>Mode</th>
                      <th>Amount</th>
                      <th>OrderId</th>
                      <th>PaymentReceivedStatus</th>
                      <th>PaymentID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proc3.map((row, i) => {
                      return (
                        <>
                          <tr>
                            <td>{row.schoolRollCode}</td>
                            <td>{row.Mode}</td>
                            <td>{row.Amount}</td>
                            <td>{row.OrderId}</td>
                            <td>{row.PaymentReceivedStatus}</td>
                            <td>{row.PaymentID}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              class="row"
              style={{
                marginBottom: "4em",
                textAlign: "center",
                marginTop: "3em",
              }}
            >
              <div className="col-sm text-center">
                <div>
                  <h2>{msg}</h2>
                </div>
                <div className="btnmain">
                  <a>
                    {" "}
                    <button
                      className={`btn btn-primary ${
                        msg.length !== 0 ? "disablePaymentBtn" : ""
                      }`}
                      onClick={insertPayment}
                    >
                      Insert Payment
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Cash;
