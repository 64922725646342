import React, { useContext, useEffect, useRef, useState } from "react";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import AdminSidebar from "../main/adminSidebar";
import axios from "axios";
import Sidebar from "../main/sidebar";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { StudentDataContext } from "../context/datacontext";
import * as CLASS_CONST from "../main/constants";
import file1 from "../../assets/pdf/pdf_school.pdf";
import terilogo from "../../assets/icons/terilogo.png";
import olympiadLogo from "../../assets/icons/olympiadLogo.jpg";

function ExamDetails() {
  const { state, dispatch } = useContext(StudentDataContext);
  const [roll_no, setRoll_no] = useState("");
  const [esdData, setEsdData] = useState([]);
  const [esdgreenData, setEsdGreenData] = useState([]);
  const [proc2, setProc2] = useState([]);
  const [proc3, setProc3] = useState([]);
  const [schoolcode_Rollno, setSchoolcode_Rollno] = useState("");
  const [subscriberType, setSubscriberType] = useState("");
  const [msg, setMsg] = useState("");
  const [amount, setAmount] = useState(0);
  const [showMsg, setShowMsg] = useState("");
  const printRef = useRef();

  const downloadPdf = async () => {
    // const input = document.getElementById('mainContent');
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("examDetails.pdf");
  };

  const downloadPdf1 = async () => {
    const pdf = new jsPDF();

    const input = document.getElementById("mainContent");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    let height = pdf.internal.pageSize.height;
    let pageHeightInPixels = inputHeight;
    // let pages = pageHeightInPixels / height;
    let pages = pageHeightInPixels / height;
    console.log(pageHeightInPixels + "," + height);

    const roundOff = Number(pages.toString().split(".")[1].substring(0, 1));
    const pageNo = roundOff > 0 ? pages + 1 : pages;
    // let pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    const totalRecords = esdData.length + esdgreenData.length;
    let pageCount = totalRecords < 1 ? 1 : Math.round(totalRecords / 15) + 2;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      // origin
      for (let i = 1; i <= pageCount; i++) {
        let pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -pdfStartingHeight,
          pdfWidth,
          pdfHeight
        );
        if (i < pageCount) {
          pdf.addPage();
        }
      }
      pdf.save("examDetails.pdf");
    });
  };

  useEffect(() => {
    const getData = async () => {
      const esdExamDetails = axios.get(
        `${API_BASE_URL}${API_END_POINTS.schoolExamDetails}`,
        {
          params: {
            sCode: `${state?.school_code}`,
            theme: "ESD",
          },
        }
      );

      const esdGreenExamDetails = axios.get(
        `${API_BASE_URL}${API_END_POINTS.schoolExamDetails}`,
        {
          params: {
            sCode: `${state?.school_code}`,
            theme: "ESDGREEN",
          },
        }
      );

      Promise.all([esdExamDetails, esdGreenExamDetails]).then(function (
        values
      ) {
        let sortedData = sortData(values[0]["data"]["data"][0]);
        let sortedData1 = sortData(values[1]["data"]["data"][0]);
        sortedData = modifySortedData(sortedData);
        sortedData1 = modifySortedData(sortedData1);
        setEsdData(sortedData);
        setEsdGreenData(sortedData1);
        if (sortedData.length === 0 && sortedData1.length === 0) {
          setShowMsg(
            "You have not paid the fees.Hence exam details are not available."
          );
        } else {
          if (checkemptyRollNo(sortedData) && checkemptyRollNo(sortedData1)) {
            setShowMsg(
              "You have not paid the fees.Hence exam details are not available."
            );
          } else {
            setShowMsg("");
          }
        }
      });
    };
    getData();
  }, []);

  const checkemptyRollNo = (inputArr) => {
    var showempty = false;
    for (let i = 0; i < inputArr.length; i++) {
      if (inputArr[i]["RollNo"] === null) {
        showempty = true;
        break;
      }
    }
    console.log("showempty", showempty);
    return showempty;
  };

  const sortData = (inputArr) => {
    return inputArr.sort(function (a, b) {
      if (a.RollNo > b.RollNo) return 1;
      if (a.RollNo < b.RollNo) return -1;
      return 0;
    });
  };

  const modifySortedData = (inputArr) => {
    for (let i = 0; i < inputArr.length; i++) {
      let outputTime = "";
      let outputTime1 = "";
      inputArr[i]["ExamSlotDateTime"] = changeDateFormat(
        inputArr[i]["ExamSlotDateTime"],
        outputTime
      );
      inputArr[i]["DemoSlotDateTime"] = changeDateFormat(
        inputArr[i]["DemoSlotDateTime"],
        outputTime1
      );
    }
    return inputArr;
  };

  const determineSubject = (row, theme) => {
    let classvalue = row.Class.trim();
    console.log(
      "CLASS_CONST.CLASS_MAPPER[theme][classvalue]",
      CLASS_CONST.CLASS_MAPPER[theme][classvalue]
    );

    return CLASS_CONST.CLASS_MAPPER[theme][classvalue];
  };

  const changeDateFormat = (inputTime, outputTime) => {
    if (inputTime !== null) {
      let timeval = inputTime.split("-");
      if (state.mode === "ONLINE") {
        outputTime +=
          timeval[2] + "-" + timeval[1] + "-" + timeval[0] + "-" + timeval[3];
      } else {
        outputTime +=
          timeval[2] + "-" + timeval[1] + "-" + timeval[0] + " 8AM-8PM";
      }

      return outputTime;
    } else {
      return "";
    }
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-3">
          {/* side bar will come here */}
          <Sidebar />
        </div>
        <div className={`col-lg-9 ${showMsg !== "" ? "" : "hideRow"}`}>
          <h2 className="msgAlignCenter">{showMsg}</h2>
        </div>
        <div className={`col-lg-9 ${showMsg !== "" ? "hideRow" : ""}`}>
          <div className="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
            <div className="section-title mb-4 text-muted">
              <h6 className="font-bold ">Exam Details</h6>
              {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
            </div>
          </div>

          <div>
            <div className="btnmain examDetailsPdf">
              <a href={file1} target="_blank">
                {" "}
                <button className={`btn btn-primary`}>
                  Download Instructions for Examination Portal
                </button>
              </a>

              <a>
                {" "}
                <button className={`btn btn-primary`} onClick={downloadPdf1}>
                  Download Exam Details
                </button>
              </a>
            </div>
          </div>
          {/* <div className="btnmain examDetailsPdf">
            <a>
              {" "}
              <button className={`btn btn-primary`} onClick={downloadPdf1}>
                Download As Pdf
              </button>
            </a>
          </div> */}

          <main className="content" id="mainContent" ref={printRef}>
            <div
              className={`shadow bg-white p-3 rounded-16`}
              style={{ border: "2px solid black" }}
            >
              <div className="table-responsive ">
                <div
                  className="section-title mb-4 text-muted"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <h6 className="font-bold ">ESD Data</h6>
                    {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                    <a className="navbar-brand reportLogo">
                      {" "}
                      <img src={terilogo} width="190" height="50" alt="logo" />
                    </a>
                  </div>

                  <div>
                    <a className="navbar-brand">
                      {" "}
                      <img
                        src={olympiadLogo}
                        width="190"
                        height="30"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>

                <table className="table table-bordered table-accent">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Password</th>
                      <th>Name</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Subject</th>
                      <th>Exam Date Time</th>
                      <th>Mock Exam Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {esdData.map((row, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{row.RollNo}</td>
                            <td>GO-{row.Password}</td>
                            <td>{row.Name}</td>
                            <td>{row.Class}</td>
                            <td>{row.Section}</td>
                            <td style={{ width: "12em" }}>
                              {determineSubject(row, "ESD")}
                            </td>
                            <td style={{ width: "10em" }}>
                              {row.ExamSlotDateTime}
                            </td>
                            <td style={{ width: "10em" }}>
                              {row.DemoSlotDateTime}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className={`shadow bg-white p-3 rounded-16 ${
                showMsg !== "" ? "hideRow" : ""
              }`}
              style={{
                marginTop: "28px",
                border: "2px solid green",
              }}
            >
              <div className="table-responsive ">
                <div
                  className="section-title mb-4 text-muted"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <h6 className="font-bold ">ESDGREEN Data</h6>
                    {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                    <a className="navbar-brand reportLogo">
                      {" "}
                      <img src={terilogo} width="190" height="50" alt="logo" />
                    </a>
                  </div>

                  <div>
                    <a className="navbar-brand">
                      {" "}
                      <img
                        src={olympiadLogo}
                        width="190"
                        height="30"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <table className="table table-bordered table-accent">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Password</th>
                      <th>Name</th>
                      <th>Class</th>
                      <th>Section</th>
                      <th>Subject</th>
                      <th>Exam Date Time</th>
                      <th>Mock Exam Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {esdgreenData.map((row, i) => {
                      return (
                        <>
                          <tr>
                            <td>{row.RollNo}</td>
                            <td>GO-{row.Password}</td>
                            <td>{row.Name}</td>
                            <td>{row.Class}</td>
                            <td>{row.Section}</td>
                            <td style={{ width: "12em" }}>
                              {determineSubject(row, "ESDGREEN")}
                            </td>
                            <td style={{ width: "10em" }}>
                              {row.ExamSlotDateTime}
                            </td>
                            <td style={{ width: "10em" }}>
                              {row.DemoSlotDateTime}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ExamDetails;
