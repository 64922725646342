import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    home: require("./translations/en/home.json"),
    registration: require("./translations/en/registration.json"),
  },
  hi: {
    home: require("./translations/hi/home.json"),
    registration: require("./translations/hi/registration.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  ns: ["home"],
  defaultNS: "home",
  saveMissing: true,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
