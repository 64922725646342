import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Sidebar from "../main/sidebar";
import img1 from "../../../src/assets/images/certificate.jpg";
import "@fontsource/libre-baskerville";
import {
  API_BASE_JAVA_URL,
  API_BASE_URL,
  API_END_POINTS,
} from "../../apis/api";
import { StudentDataContext } from "../context/datacontext";
import SidebarStudent from "../main/sidebarStudent";
import SidebarIn from "../main/SidebarIn";

export default function DownloadYouthCertificate() {
  const { state, dispatch } = useContext(StudentDataContext);
  console.log("state", state);
  const [rollNoList, setRoll_noList] = useState([
    { rollno: 1, marks: "50" },
    { rollno: 2, marks: "80" },
    { rollno: 3, marks: "95" },
  ]);
  const [rollno, setRolNo] = useState("");
  const [theme, setTheme] = useState("");
  const [certImg, setCertImg] = useState("");
  const [absentMsg, setAbsentMsg] = useState("");
  const [namevalue, setNameValue] = useState("");
  const [classvalue, setClass] = useState("");
  const [school, setSchool] = useState("");
  const [setStudentCertData, setStudentCertificateData] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [disabledBtnFlag, setDisabledBtnFlag] = useState(true);
  const [add, setAdd] = useState("National Victor Public School");
  const printRef = useRef();

  const changerollNo = (rollValue) => {
    console.log("rollValue", rollValue);
    setCertImg(rollValue);
    let obj = JSON.parse(rollValue);
    // let rollObj = JSON.parse(rollValue);
    if (obj.certificatetype === "CERTIFICATE OF PARTICIPATION") {
      setCertImg("participation");
      setNameValue(obj.name);
      setClass(obj.class);
      setSchool(obj.school);
      setDisabledBtnFlag(false);
      setAbsentMsg("");
    } else if (obj.certificatetype === "CERTIFICATE OF DISTINCTION") {
      setCertImg("distinction");
      setNameValue(obj.name);
      setClass(obj.class);
      setSchool(obj.school);
      setDisabledBtnFlag(false);
      setAbsentMsg("");
    } else if (obj.certificatetype === "CERTIFICATE OF MERIT") {
      setCertImg("merit");
      setNameValue(obj.name);
      setClass(obj.class);
      setSchool(obj.school);
      setDisabledBtnFlag(false);
      setAbsentMsg("");
    } else if (obj.certificatetype === "ABSENT") {
      setCertImg("");
      setNameValue("");
      setClass("");
      setSchool("");
      setDisabledBtnFlag(true);
      setAbsentMsg(`Dear Student, 
      You have not appeared in the GREEN Olympiad 2023 examination.`);
    }
    setTheme(obj.theme);
    setRolNo(obj.rollNo);
  };

  const constructDropdownData = (list) => {
    let dropdownvalue = [];
    for (let i = 0; i < list.length; i++) {
      let obj = {};
      obj["value"] = `${list[i]["RollNo"]} - ${list[i]["Name"]}`;
      obj["key"] = JSON.stringify({
        name: list[i]["Name"],
        certificatetype: list[i]["certificatetype"],
        class: list[i]["Class"],
        school: list[i]["SchoolName"],
        rollNo: list[i]["RollNo"],
        theme: list[i]["examtheme"],
      });
      dropdownvalue.push(obj);
    }
    setDropdown(dropdownvalue);
  };

  const getAllStudentData = async () => {
    const studentCertificateData = await axios.get(
      `${API_BASE_URL}${API_END_POINTS.getstudentCertificateData}`,
      {
        params: {
          school_code: state.roll_no,
        },
      }
    );

    if (studentCertificateData?.status && studentCertificateData?.data) {
      console.log("studentCertificateData", studentCertificateData);
      if (studentCertificateData.data.data.length > 0) {
        setStudentCertificateData(studentCertificateData.data.data);
        constructDropdownData(studentCertificateData.data.data);
        let obj = {
          certificatetype:
            studentCertificateData.data.data[0]["certificatetype"],
          name: studentCertificateData.data.data[0]["Name"],
          class: studentCertificateData.data.data[0]["Class"],
          school: studentCertificateData.data.data[0]["SchoolName"],
          rollNo: studentCertificateData.data.data[0]["RollNo"],
          theme: studentCertificateData.data.data[0]["examtheme"],
        };
        changerollNo(JSON.stringify(obj));
      } else {
        setCertImg("");
        setNameValue("");
        setClass("");
        setSchool("");
        // setDisabledBtnFlag(false);
        setAbsentMsg(`Dear Student, 
        You have not appeared in the GREEN Olympiad 2023 examination.`);
      }
    }
  };

  useEffect(() => {
    getAllStudentData();
  }, []);

  const downloadPdf = async () => {
    // const input = document.getElementById('mainContent');
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF("l", "px", "a4");
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    console.log("pdfWidth", pdfWidth);
    console.log("pdfHeight", pdfHeight);
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("certficate.pdf");
    updatedownloadCount();
  };

  const updatedownloadCount = async () => {
    console.log("rollno", rollno);
    if (rollno !== "") {
      const updatedownloadCountValue = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.updateCertificateCount}`,
        {
          params: {
            roll_no: rollno,
          },
        }
      );
    }
  };

  // const downloadPdf = async () => {
  //   // const input = document.getElementById('mainContent');
  //   const element = printRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF();
  //   const imgProperties = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  //   console.log("pdfHeight", pdfHeight);
  //   pdf.addImage(data, "PNG", 0, 0, 290, 155);
  //   pdf.save("cerrtificate.pdf");
  // };
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-3">
          {/* side bar will come here */}
          <SidebarIn />
        </div>
        <div className="col-lg-9 ">
          <main className="content">
            {/* <form> */}
            <div class="row">
              <div className="col-sm-8">
                <div class="form-wrapper">
                  <label>Roll No:</label>
                  <select
                    className={`dropdown`}
                    id="certDropdown"
                    value={rollno}
                    onChange={(e) => {
                      let rollNoObj = JSON.parse(e.target.value);
                      setRolNo(rollNoObj.rollNo);
                      changerollNo(e.target.value);
                    }}
                  >
                    <option value="volvo" disabled>
                      Select Roll No
                    </option>
                    {dropdown?.map((co) => {
                      return <option value={co.key}>{co.value}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div class="form-wrapper">
                  <button
                    className={`btn btn-primary ${
                      disabledBtnFlag ? "disablePaymentBtn" : ""
                    }`}
                    onClick={downloadPdf}
                  >
                    Download Certificate
                  </button>
                </div>
              </div>
            </div>
            {/* </form> */}

            <div>
              <div className="certContainer" ref={printRef}>
                {absentMsg === "" && (
                  <div
                    className="certDownload"
                    style={{
                      backgroundImage: `url(${theme}/cert_${certImg}.jpg)`,
                    }}
                  >
                    <span
                      className={`certFont ${
                        certImg === "participation"
                          ? "certInName"
                          : "certInName1"
                      } ${disabledBtnFlag || namevalue === "" ? "hide" : ""}`}
                    >
                      {namevalue}
                    </span>

                    {/* <input
                        className={`certFont ${
                          certImg === "participation"
                            ? "certInName"
                            : "certInName1"
                        } ${disabledBtnFlag || namevalue === "" ? "hide" : ""}`}
                        type="text"
                        placeholder=""
                        name="uname"
                        value={namevalue}
                      /> */}
                    {/* <input
                        className={`certFont ${
                          certImg === "participation" ? "certCls" : "certCls1"
                        } ${
                          disabledBtnFlag || classvalue === "" ? "hide" : ""
                        }`}
                        type="text"
                        placeholder=""
                        name="uname"
                        value={classvalue}
                      /> */}

                    <span
                      className={`certFont ${
                        certImg === "participation" ? "certInAdd" : "certAdd1"
                      } ${disabledBtnFlag || school === "" ? "hide" : ""}`}
                    >
                      {school}
                    </span>

                    {/* <input
                        className={`certFont ${
                          certImg === "participation" ? "certInAdd" : "certAdd1"
                        } ${disabledBtnFlag || school === "" ? "hide" : ""}`}
                        type="text"
                        placeholder=""
                        name="address"
                        value={school}
                      /> */}
                  </div>
                )}

                {absentMsg !== "" && (
                  <div className="absentdiv">
                    <p>{absentMsg}</p>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
