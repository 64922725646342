export const LEVEL_MAPPER = {
  ESD: {
    Level_1: "ESD (Class 4,5)",
    Level_2: "ESD (Class 6,7,8)",
    Level_3: "ESD (Class 9,10)",
    Level_4: "ESD (Class 11,12)",
  },
  ESDGREEN: {
    Level_1: "ESDGREEN (Class 4,5)",
    Level_2: "ESDGREEN (Class 6,7,8)",
    Level_3: "ESDGREEN (Class 9,10)",
    Level_4: "ESDGREEN (Class 11,12)",
  },
  Go4Youth: {
    Level_5: "Go4Youth (UG / PG )",
  },
};

export const CLASS_MAPPER = {
  ESD: {
    4: "ESD Level 1 (Class 4,5)",
    5: "ESD Level 1 (Class 4,5)",
    6: "ESD Level 2 (Class 6,7,8)",
    7: "ESD Level 2 (Class 6,7,8)",
    8: "ESD Level 2 (Class 6,7,8)",
    9: "ESD Level 3 (Class 9,10)",
    10: "ESD Level 3 (Class 9,10)",
    11: "ESD Level 4 (Class 11,12)",
    12: "ESD Level 4 (Class 11,12)",
  },
  ESDGREEN: {
    4: "ESDGREEN Level 1 (Class 4,5)",
    5: "ESDGREEN Level 1 (Class 4,5)",
    6: "ESDGREEN Level 2 (Class 6,7,8)",
    7: "ESDGREEN Level 2 (Class 6,7,8)",
    8: "ESDGREEN Level 2 (Class 6,7,8)",
    9: "ESDGREEN Level 3 (Class 9,10)",
    10: "ESDGREEN Level 3 (Class 9,10)",
    11: "ESDGREEN Level 4 (Class 11,12)",
    12: "ESDGREEN Level 4 (Class 11,12)",
  },
  Go4Youth: {
    Level_5: "Go4Youth (UG / PG )",
  },
};
