import Header from "./pages/main/Header";
import Footer from "./pages/main/Footer";
import Home from "./pages/main/Home";
import SchoolLogin from "./pages/school/SchoolLogin";
import SchoolRegistration from "./pages/school/SchoolRegistration";
import SchoolEditDetails from "./pages/school/SchoolEditDetails";
import SchoolUploadData from "./pages/school/SchoolUploadData";
import SchoolPayment from "./pages/school/SchoolPayment";
import SchoolSlot from "./pages/school/SchoolSlot";
import SchoolApplicationStatus from "./pages/school/SchoolApplicationStatus";
import SchoolHelpdeskTicket from "./pages/school/SchoolHelpdeskTicket";
import SchoolViewHelpdeskTicket from "./pages/school/SchoolViewHelpdeskTicket";
import SchoolCertificate from "./pages/school/SchoolCertificate";
import SchoolChangePassword from "./pages/school/SchoolChangePassword";
import SchoolForget from "./pages/school/SchoolForget";

import StudentLogin from "./pages/student/StudentLogin";
import StudentRegistration from "./pages/student/StudentRegistration";
import StudentEditDetails from "./pages/student/StudentEditDetails";
import StudentUploadData from "./pages/student/StudentUploadData";
import StudentPayment from "./pages/student/StudentPayment";
import StudentSlot from "./pages/student/StudentSlot";
import StudentApplicationStatus from "./pages/student/StudentApplicationStatus";
import StudentHelpdeskTicket from "./pages/student/StudentHelpdeskTicket";
import StudentViewHelpdeskTicket from "./pages/student/StudentViewHelpdeskTicket";
import StudentCertificate from "./pages/student/StudentCertificate";
import StudentChangePassword from "./pages/student/StudentChangePassword";
import StudentForget from "./pages/student/StudentForget";

import { Routes, Route } from "react-router-dom";
import StudentDataProvider from "./pages/context/datacontext";
import Instructions from "./pages/main/instructions";
import { useLocation, useNavigate } from "react-router";
import SchoolRollNoGenerate from "./pages/school/SchoolRollNoGenerate";
import { useCallback, useEffect, useState } from "react";
import StudentIndigoRegistration from "./pages/indigo/StudentRegistration";
import SchoolInLogin from "./pages/indigo/StudentLogin";
import StudentInEditDetails from "./pages/indigo/StudentEditDetails";
import StudentInSlot from "./pages/indigo/StudentSlot";
import StudentInPayment from "./pages/indigo/StudentPayment";
import StudentInApplicationStatus from "./pages/indigo/StudentApplicationStatus";
import StudentInHelpdeskTicket from "./pages/indigo/StudentHelpdeskTicket";
import StudentInViewHelpdeskTicket from "./pages/indigo/StudentViewHelpdeskTicket";
import StudentInChangePassword from "./pages/indigo/StudentChangePassword";
import StudentInforget from "./pages/indigo/StudentForget";
import HomeAdmin from "./pages/admin/main";
import AdminLogin from "./pages/admin/adminLogin";
import AdminPayment from "./pages/admin/adminPayment";
import HelpDeskViewHelpdeskTicket from "./pages/admin/ViewHelpdesk";
import DownloadReport from "./pages/admin/downloadReport";
import SchoolOfflineRegistration from "./pages/admin/SchoolOfflineRegistration";
import BulkEmail from "./pages/admin/bulkEmail";
import UpdatePayment from "./pages/admin/updatePayment";
import BulkSms from "./pages/admin/bulkSms";
import DailyRegistration from "./pages/admin/dailyRegistration";
import Cash from "./pages/admin/cash";
import GenerateRoll from "./pages/admin/generateRoll";
import ExamDetails from "./pages/school/examdetails";
import StudentExamDetails from "./pages/student/StudentExamDetails";
import StudentIndigoExamDetails from "./pages/indigo/StudentIndigoExamDetails";
import NameChange from "./pages/school/nameChange";
import NameStudentChange from "./pages/student/nameChange";
import NameIndigoChange from "./pages/indigo/nameChange";
import DownloadCertificate from "./pages/school/downloadCertificate";
import DownloadStudentCertificate from "./pages/student/downloadCertificate";
import DownloadYouthCertificate from "./pages/indigo/downloadCertificate";
import { IdleTimerProvider } from "react-idle-timer";
import MaterialDetails from "./pages/school/materialDetails";
import SchoolHome from "./pages/main/SchoolHome";
// import ChangeCredentials from "./pages/school/changeCredentials";
function App() {
  // let login = localStorage.getItem(JSON.parse('login'));
  // console.log("login", login);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const GO4YOUTH_ENV = "https://go4youth.com/";
  const [isAdmin, setIsAdmin] = useState({});
  const navigate = useNavigate();
  // const location = useLocation();
  // const { hash, pathname, search } = location;

  const handleLogged = (flag) => {
    console.log("handleLogged", flag);
    setIsLoggedIn(flag);
  };

  const handeAdmin = (obj) => {
    console.log("handeAdmin", obj);
    setIsAdmin((prevObj) => {
      return { ...prevObj, ...obj };
    });
  };

  const onIdle = () => {
    window.location.reload();
  };

  // const handleBackButton = () => {
  //   // Go back to the previous route

  //   // if (location.pathname === "/school-login") {
  //   setIsLoggedIn(false);
  //   navigate(-1);
  //   // }
  // };

  // useEffect(() => {
  //   window.addEventListener("popstate", handleBackButton);
  //   return () => window.removeEventListener("popstate", handleBackButton);
  // }, []);

  useEffect(() => {
    console.log("isLoggedIn", isLoggedIn);
    // if (!isLoggedIn) {
    //   navigate("/");
    // }
  }, []);

  useEffect(() => {
    if (isAdmin.isLoggedIn) {
      navigate(`/${isAdmin.page}`);
    }
  }, [isAdmin]);

  return (
    <>
      {/* <BrowserRouter basename="/"> */}
      {/*  15 minutes timer */}

      <IdleTimerProvider onIdle={onIdle} timeout={1000 * 60 * 20}>
        <StudentDataProvider>
          <Header isLogged={handleLogged} />

          <Routes>
            {/* School */}
            {/* {window.location.href == GO4YOUTH_ENV && !isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/"
                  element={<Home isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            {window.location.href !== GO4YOUTH_ENV && !isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/"
                  element={<SchoolHome isLogged={handleLogged} />}
                  exact
                />
              </>
            )} */}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/"
                  element={<SchoolHome isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            {/* {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/"
                  element={<SchoolHome isLogged={handleLogged} />}
                  exact
                />
              </>
            )} */}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/school-login"
                  element={<SchoolLogin isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/student-login"
                  element={<StudentLogin isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/student-Inlogin"
                  element={<SchoolInLogin isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            <>
              {" "}
              <Route
                path="/admin"
                element={<HomeAdmin isLogged={handeAdmin} />}
                exact
              />
            </>

            {isAdmin.page === "admin-login" && isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-login"
                  element={<AdminLogin isLogged={handeAdmin} />}
                  exact
                />
              </>
            )}

            {/* {(isAdmin.page === 'admin-payment' && isAdmin.isLoggedIn === true) && ( */}
            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-payment"
                  element={<AdminPayment isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-generateRoll"
                  element={<GenerateRoll isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-daily-registration"
                  element={<DailyRegistration isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-helpdesk"
                  element={<HelpDeskViewHelpdeskTicket isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-paymentReport"
                  element={<DownloadReport isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-offline-registration"
                  element={
                    <SchoolOfflineRegistration
                      isAdmin={isAdmin}
                      isLogged={handeAdmin}
                    />
                  }
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-bulk-email"
                  element={<BulkEmail isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-bulk-sms"
                  element={<BulkSms isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-update-payment"
                  element={<UpdatePayment isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {isAdmin.isLoggedIn === true && (
              <>
                {" "}
                <Route
                  path="/admin-cash"
                  element={<Cash isAdmin={isAdmin} />}
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/goForYouth"
                  element={<Home isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/school-registration"
                  element={<SchoolRegistration isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/student-registration"
                  element={<StudentRegistration isLogged={handleLogged} />}
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/student-Indigo-registration"
                  element={
                    <StudentIndigoRegistration isLogged={handleLogged} />
                  }
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/student-forget"
                  element={<StudentForget />}
                  exact
                />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route path="/school-forget" element={<SchoolForget />} exact />
              </>
            )}

            {!isLoggedIn && (
              <>
                {" "}
                <Route
                  path="/student-Inforget"
                  element={<StudentInforget />}
                  exact
                />
              </>
            )}

            {/* test */}

            {isLoggedIn && (
              <>
                <Route path="/Instructions" element={<Instructions />} exact />
                <Route
                  path="/school-edit-details"
                  element={<SchoolEditDetails />}
                  exact
                />
                <Route
                  path="/school-upload-data"
                  element={<SchoolUploadData />}
                  exact
                />
                <Route path="/name-change" element={<NameChange />} exact />
                <Route
                  path="/school-rollNo"
                  element={<SchoolRollNoGenerate />}
                  exact
                />
                <Route
                  path="/school-payment"
                  element={<SchoolPayment />}
                  exact
                />
                <Route path="/school-slot" element={<SchoolSlot />} exact />
                <Route path="/student-slot" element={<StudentSlot />} exact />
                <Route path="/exam-details" element={<ExamDetails />} exact />
                <Route
                  path="/school-application-status"
                  element={<SchoolApplicationStatus />}
                  exact
                />
                <Route
                  path="/material-details"
                  element={<MaterialDetails />}
                  exact
                />
                <Route
                  path="/school-helpdesk-ticket"
                  element={<SchoolHelpdeskTicket />}
                  exact
                />
                <Route
                  path="/school-view-helpdesk-ticket"
                  element={<SchoolViewHelpdeskTicket />}
                  exact
                />
                <Route
                  path="/school-certificate"
                  element={<SchoolCertificate />}
                  exact
                />
                <Route
                  path="/school-change-password"
                  element={<SchoolChangePassword />}
                  exact
                />
                <Route
                  path="/download-certificate"
                  element={<DownloadCertificate />}
                  exact
                />
                {/* Student */}
                {/* <Route path="/" element={<Home />} exact />
                StudentInEditDetails */}
                <Route
                  path="/student-edit-details"
                  element={<StudentEditDetails />}
                  exact
                />
                <Route
                  path="/student-download-details"
                  element={<DownloadStudentCertificate />}
                  exact
                />
                <Route
                  path="/name-student-change"
                  element={<NameStudentChange />}
                  exact
                />
                <Route
                  path="/student-upload-data"
                  element={<StudentUploadData />}
                  exact
                />
                <Route
                  path="/student-payment"
                  element={<StudentPayment />}
                  exact
                />
                <Route
                  path="/student-indigo-payment"
                  element={<NameIndigoChange />}
                  exact
                />
                <Route
                  path="/student-exam-details"
                  element={<StudentExamDetails />}
                  exact
                />
                <Route
                  path="/student-application-status"
                  element={<StudentApplicationStatus />}
                  exact
                />
                <Route
                  path="/student-helpdesk-ticket"
                  element={<StudentHelpdeskTicket />}
                  exact
                />
                <Route
                  path="/student-view-helpdesk-ticket"
                  element={<StudentViewHelpdeskTicket />}
                  exact
                />
                <Route
                  path="/student-certificate"
                  element={<StudentCertificate />}
                  exact
                />
                <Route
                  path="/student-change-password"
                  element={<StudentChangePassword />}
                  exact
                />
                {/* go for youth */}
                <Route
                  path="/student-Inedit-details"
                  element={<StudentInEditDetails />}
                  exact
                />
                <Route
                  path="/youth-certificate-details"
                  element={<DownloadYouthCertificate />}
                  exact
                />
                <Route
                  path="/student-Inslot"
                  element={<StudentInSlot />}
                  exact
                />
                <Route
                  path="/student-Inpayment"
                  element={<StudentInPayment />}
                  exact
                />
                <Route
                  path="/student-indigo-exam-details"
                  element={<StudentIndigoExamDetails />}
                  exact
                />
                <Route
                  path="/student-Inapplication-status"
                  element={<StudentInApplicationStatus />}
                  exact
                />
                <Route
                  path="/student-Inhelpdesk-ticket"
                  element={<StudentInHelpdeskTicket />}
                  exact
                />
                <Route
                  path="/student-Inview-helpdesk-ticket"
                  element={<StudentInViewHelpdeskTicket />}
                  exact
                />
                <Route
                  path="/student-Inchange-password"
                  element={<StudentInChangePassword />}
                  exact
                />
              </>
            )}
          </Routes>
          <Footer />
        </StudentDataProvider>
      </IdleTimerProvider>
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
