import React, { useContext, useEffect, useState } from "react";
import Header from "../main/Header";
import { Link } from "react-router-dom";
import schoolimg from "../../assets/icons/school.png";
import { useNavigate } from "react-router";
import "../../assets/css/style_new.css";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import { StudentDataContext } from "../context/datacontext";
import { notify } from "../../Utills";
import file from "../../assets/pdf/guide_school.pdf";
import file1 from "../../assets/pdf/pdf_school.pdf";
import offlineFile from "../../assets/pdf/offlineform.pdf";
import finalGoIndia from "../../assets/pdf/finalGoIndia.pdf";
import finalGoInternational from "../../assets/pdf/finalGoInternational.pdf";
import registration_interForm from "../../assets/pdf/registration_interForm.pdf";

export default function SchoolLogin({ isLogged }) {
  const { state, dispatch } = useContext(StudentDataContext);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const initialState = {
    schoolname: "",
    country: "",
    state: "",
    pincode: "",
    postal_address: "",
    phonestd: "",
    mobile: "",
    principal_name: "",
    email: "",
    district: "",
    coordinating_teacher: "",
    school_code: "",
    email_coordinator: "",
    mobile_coordinator: "",
    mode: "",
    allow_School_slotting: false,
    student: { Class: 4 },
    roll_no: "",
  };
  const navigate = useNavigate();
  const [userError, setUserError] = useState({});
  const [passError, setPassError] = useState({});
  const setUserName = (e) => {
    let val = e.target.value;
    if (val === "") {
      setUserError({ user: "Please enter School code" });
    } else {
      setUserError({ user: "" });
    }
    setUser(val);
  };

  useEffect(() => {
    dispatch({
      type: "EMPTY_STATE",
      schoolname: "",
      country: "",
      state: "",
      pincode: "",
      postal_address: "",
      phonestd: "",
      mobile: "",
      principal_name: "",
      email: "",
      district: "",
      coordinating_teacher: "",
      school_code: "",
      email_coordinator: "",
      mobile_coordinator: "",
      mode: "",
      allow_School_slotting: false,
      student: { Class: 4 },
      roll_no: "",
    });
  }, []);

  const setPassword = (e) => {
    let val = e.target.value;
    if (val === "") {
      setPassError({ pass: "Please enter Password" });
    } else {
      setPassError({ pass: "" });
    }
    setPass(val);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!(user && pass)) {
      if (user === "") {
        setUserError({ user: "Please enter School code" });
      }
      if (pass === "") {
        setPassError({ pass: "Please enter Password" });
      }
      return;
      // console.log('test T')
    } else {
      const serverData = {
        username: user,
        password: pass,
      };

      axios
        .post(`${API_BASE_URL}${API_END_POINTS?.login}`, serverData)
        //.post(`${API_END_POINTS?.login}`, serverData)
        .then((res) => {
          if (res.data && res?.data?.data) {
            const {
              data: {
                schoolsCode,
                principalname,
                schoolname,
                country,
                state,
                pincode,
                mobile,
                email,
              },
            } = res.data;
            console.log("res.data", res.data);
            isLogged(true);
            // if (res.data.data.Mode === "OFFLINE") {
            navigate("/school-edit-details");
            // } else {
            //   navigate("/school-application-status");
            // }

            // navigate("/material-details");

            // alert("login successfull!");
            // notify(`login successfull!!.`, true);
            // history.push()
            // localStorage.removeItem("token");
            // localStorage.setItem("token", res?.data?.token);
            // let token = res?.data?.token;
            // let decoded = token !== "" ? jwt_decode(token) : {};
            dispatch({
              type: "ADDINFO",
              school_code: serverData.username,
              schoolname: res.data.data.schoolname,
              country: res.data.data.country,
              state: res.data.data.state,
              pincode: res.data.data.pincode,
              postal_address: res.data.data.PostalAddress,
              phonestd: res.data.data.PhoneStd,
              mobile: res.data.data.mobile,
              principal_name: res.data.data.principalname,
              email: res.data.data.email,
              district: res.data.data.district,
              coordinating_teacher: res.data.data.coordinating_teacher,
              email_coordinator: res.data.data.email_coordinator,
              mobile_coordinator: res.data.data.mobile_coordinator,
              mode: res.data.data.Mode,
            });
          } else {
            // alert("something is rong");
            setLoginErr("Either your login id or password do not match");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // navigate("/school-edit-details");
  };
  console.log("===user error", userError, passError);
  return (
    <div className="container-login">
      {/* <marquee> Welcome to GREEN Olympiad</marquee> */}
      {/* <div className="container-inner-area">
        <Link to="/school-registration">
          <div className="smallcards-login">
            <div className="card-body">
              <img className="card-login-img" src={schoolimg} />
              <h4 className="card-text">
                NEW SCHOOL REGISTRATIONS <br /> ( Std 4-12 )
              </h4>
            </div>
          </div>
        </Link>
        <div className="login-card">
          <div className="imgcontainer">
            <h2>User Login</h2>
          </div>
          <div className="">
            <label>School Code:</label>
            <input
              type="text"
              placeholder="school code"
              name="uname"
              required
              onChange={setUserName}
            />
            <br />
            {
              userError?.user && userError.user.length > 5 ? <span className="error">{userError.user}</span> : null
            }
            <br />
            <label>Password:</label>

            <input type="password" placeholder="password" name="psw" required onChange={setPassword} />
            <br />
            {
              passError?.pass && passError.pass.length > 5 ? <span className="error">{passError.pass}</span> : null
            }
            <div>

              <button className="login-btn"
                onClick={handleSubmit}
              >
                Login
              </button>

            </div>
          </div>
          <Link to="/school-forget" className="forget">
            Forgot password?
          </Link>
        </div>
      </div> */}

      <div>
        <div className="my-5 login">
          <div className="container-fluid">
            <form>
              <div className="row w-100 justify-content-center">
                <div className="col-sm-6  col-md-12 col-lg-5">
                  <div className="card shadow p-3 p-md-4 text-center">
                    {/* <div className="section-title mb-4 text-muted">
                      <h6 className="font-bold ">Don't have a School Code ?</h6>
                    </div> */}
                    <div className="w-100">
                      {/* <p className="fs-5 font-bold">
                        {" "}
                        New schools click below for generating your School Code
                      </p> */}
                      <div className="text-sm-center">
                        <h3
                          className="text-center"
                          style={{ color: "#0d6efd", fontWeight: "bold" }}
                        >
                          GREEN Olympiad 2023 results are out!!
                        </h3>
                        <h3
                          className="text-center"
                          style={{ color: "#0d6efd", fontWeight: "bold" }}
                        >
                          To download the scores of students in your respective
                          schools kindly follow the instructions below:
                        </h3>
                        <br />
                        <h4 style={{ color: "#0d6efd", fontWeight: "bold" }}>
                          GREEN Olympiad mock question papers and examination
                          material for 12 October exam date are dispatched to
                          schools. Kindly check the tracking status by logging
                          in at
                          <a
                            className="mx-2"
                            href="https://www.terigreenolympiad.com"
                          >
                            https://www.terigreenolympiad.com
                          </a>
                          using your school code and password.
                        </h4>

                        <br />
                        <h4
                          style={{
                            color: "#0d6efd",
                            fontWeight: "bold",
                          }}
                        >
                          <ol className="text-start">
                            <li>
                              Log in through this portal using your school code
                              and password.
                            </li>
                            <li>
                              On your dashboard, refer to 'Application Status'
                              on the left hand bar. List of students, their
                              details, scores and category of certificate
                              awarded will be visible.
                            </li>
                            <li>
                              You can also download the results from the tab
                              available on the same page.
                            </li>
                            <li>
                              The certificates will be printed and dispatched on
                              the registered school address.
                            </li>
                          </ol>
                        </h4>

                        <h4 style={{ color: "#0d6efd", fontWeight: "bold" }}>
                          Schools that have adopted bank transfer as their
                          option to remit the registration fees and have not
                          shared details of the transaction. Kindly get in touch
                          with GREEN Olympiad Secretariat at{" "}
                          <a className="mx-2" href="go@teri.res.in">
                            go@teri.res.in
                          </a>
                          . Kindly note that your registration could be on hold.
                          using your school code and password.
                        </h4>
                        <br />

                        <h4 style={{ color: "#0d6efd", fontWeight: "bold" }}>
                          The examination date of 01 September 2023 is changed
                          to 02 November 2023. All schools who have opted for 01
                          September 2023 as their examination date, will now
                          appear for the examination on 02 November 2023.
                        </h4>
                        <h4>
                          {" "}
                          <a
                            style={{
                              fontSize: "20px",
                              color: "green",
                              fontWeight: "bold",
                            }}
                            href={finalGoIndia}
                            target="_blank"
                          >
                            Indian Schools
                          </a>
                          {" | "}
                          <a
                            style={{
                              fontSize: "20px",
                              color: "green",
                              fontWeight: "bold",
                            }}
                            href={finalGoInternational}
                            target="_blank"
                          >
                            International Schools
                          </a>{" "}
                        </h4>
                      </div>
                      <div className="text-sm-center mt-4 mt-md-5">
                        <Link
                          to="/school-registration"
                          className="link-light my-2"
                          style={{ color: "black", fontSize: "25px" }}
                        >
                          <button
                            style={{ color: "white" }}
                            className="btn btn-primary btnReg w-50"
                          >
                            Click Here
                          </button>
                        </Link>
                      </div>
                      {/* <div className="loginbox w-100"> */}
                      <div className="loginInner">
                        {/* <h3>School</h3> */}
                        {/* <h4>
                          As a FIRST STEP, click on the 'Registration' link
                          below and proceed further
                        </h4> */}
                        <div
                          className="newSchool text-center"
                          style={{ background: "white" }}
                        >
                          <img src="images/new-school.png" alt="" />
                          {/* <h4>New School</h4> */}
                          <h5>
                            <Link
                              to="/school-registration"
                              className="link-light my-2"
                              style={{ color: "black", fontSize: "25px" }}
                            >
                              New User Click Here for Generating School Code
                            </Link>
                          </h5>

                          <p
                            style={{
                              fontSize: "25px",
                              color: "black",
                            }}
                          >
                            STD 4-12
                          </p>
                          <h5>
                            <a
                              style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                color: "black",
                              }}
                              className="link-light"
                              href={file1}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Instructions for Registration
                            </a>
                          </h5>
                        </div>
                        <div
                          style={{
                            marginTop: "14px",
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          <a
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                            className="link-light"
                            href={file}
                            target="_blank"
                          >
                            Guidelines to fill Registration form
                          </a>
                          <p
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            Before filling the form,make sure you read all the
                            instructions
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-12 col-lg-5 mt-3 mt-sm-0">
                  <div className="card shadow p-3 p-md-4 text-center">
                    <div className="section-title mb-4 text-muted">
                      <h6 className="font-bold ">
                        Already have a School Code, kindly login
                      </h6>
                      {/* <p>Welcome to school online registration</p> */}
                    </div>

                    <div className="">
                      <div className="form-wrapper">
                        {/* <input type="text" className="" placeholder="School Code" /> */}
                        <input
                          type="text"
                          placeholder="School code"
                          name="uname"
                          required
                          autocomplete="off"
                          onChange={setUserName}
                        />
                        <br />
                        {userError?.user && userError.user.length > 5 ? (
                          <span className="error">{userError.user}</span>
                        ) : null}
                      </div>
                      <div className="form-wrapper">
                        {/* <input type="password" class="" placeholder="Password" /> */}
                        <input
                          type="password"
                          placeholder="Password"
                          name="psw"
                          autocomplete="off"
                          required
                          onChange={setPassword}
                        />
                        <br />
                        {passError?.pass && passError.pass.length > 5 ? (
                          <span className="error">{passError.pass}</span>
                        ) : null}
                        <div className="text-end mt-3">
                          {/* <a href="forget-password.html" class="forgetpwd">Forgot Password</a> */}
                          <Link to="/school-forget" className="forget">
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="text-sm-center mt-4 mt-md-5">
                        <button
                          style={{ color: "white" }}
                          className="btn btn-primary btnReg w-50"
                          onClick={handleSubmit}
                        >
                          Login
                        </button>
                      </div>
                      <h4 style={{ textAlign: "center" }}>{loginErr}</h4>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "14px",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <a
                    style={{
                      fontSize: "20px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    href={file}
                    target="_blank"
                  >
                    Guidelines for online registration can be viewed here
                  </a>
                </div>
                <div
                  style={{
                    marginTop: "14px",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  <h3 style={{ color: "red" }}>
                    For offline registration, download the registration form:{" "}
                    <a
                      style={{
                        fontSize: "20px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                      href={finalGoIndia}
                      target="_blank"
                    >
                      Indian Schools
                    </a>
                    {" | "}
                    <a
                      style={{
                        fontSize: "20px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                      href={finalGoInternational}
                      target="_blank"
                    >
                      International Schools
                    </a>
                  </h3>
                  <a
                    style={{
                      fontSize: "20px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    href={offlineFile}
                    target="_blank"
                  >
                    For offline registration, kindly download and print the pdf
                    of registration form by clicking here
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
