import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { StudentDataContext } from "../context/datacontext";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import SidebarStudent from "../main/sidebarStudent";
import SidebarIn from "../main/SidebarIn";

function NameIndigoChange() {
  const { state, dispatch } = useContext(StudentDataContext);
  const [rollNo, setRollNo] = useState("");
  const [sname, setsname] = useState("");
  const [oldName, setOldName] = useState("");
  const [newName, setNewName] = useState("");
  const [sNameMessage, setsNameMessage] = useState("");
  const [errordisp, setErrordisp] = useState("");
  //   const [issubmitAllowed, setIsSubmitAllowed] = useState(true);
  useEffect(() => {
    getStudentNameDetails();
    setRollNo(state?.roll_no);
    setsname(state?.student?.school);
    // getStudentDetails();
  }, []);

  const updateschool = async () => {
    const studentData = await axios.get(
      `${API_BASE_URL}${API_END_POINTS.updatedetails}`,
      {
        params: {
          school: `${sname}`,
          roll_no: `${state?.roll_no}`,
        },
      }
    );
    console.log("studentData", studentData);
    if (studentData?.status) {
      // if (studentData?.data?.data?.NewValue) {
      //   setNewName(studentData?.data?.data.NewValue);
      // }
      if (studentData?.data) {
        setOldName(studentData?.data?.data?.Name);
        setsNameMessage("School Name Updated Successfully");
        //   setRollNoError("");
      } else {
        setsNameMessage("There is some error in updating the school name");
      }
    }
  };

  //   state?.roll_no
  const getStudentNameDetails = async () => {
    const studentData = await axios.get(
      `${API_BASE_URL}${API_END_POINTS.getName}`,
      {
        params: {
          roll_no: `${state?.roll_no}`,
          type: "go4youth",
        },
      }
    );
    console.log("studentData", studentData);
    if (studentData?.status && studentData?.data) {
      // if (studentData?.data?.data?.NewValue) {
      //   setNewName(studentData?.data?.data.NewValue);
      // }
      if (studentData?.data?.data?.Name) {
        setOldName(studentData?.data?.data?.Name);
        //   setRollNoError("");
      } else {
        setOldName("");
        //   setRollNoError("There is no record for this roll no");
      }
      setNewName("");
    }
  };

  const updateName = async () => {
    if (!oldName || !newName) {
      setErrordisp("Name cannot be updated");
    } else {
      setErrordisp("");
      const studentData = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.updateName}`,
        { oldName, newName, roll_no: rollNo, type: "go4youth" }
      );
      console.log("studentData", studentData);
      if (studentData?.status && studentData?.data) {
        if (studentData?.data?.data?.length > 0) {
          setErrordisp(`${studentData?.data?.data[0]["@msg"]}`);
          //   setIsSubmitAllowed(false);
        }
      }
    }
    setOldName("");
    setNewName("");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-3">
            {/* side bar will come here */}
            <SidebarIn />
          </div>
          <div className="col-lg-9 ">
            <div className="application-status p-5 pb-50-xs">
              <div className="page-heading">
                <h4>
                  <b>Certificate Edit:</b>
                </h4>
                {/* <p>Check Payment details</p> */}
              </div>

              <div class="shadow mb-5 rounded-16">
                <div class="p-4">
                  <div class="form-wrapper">
                    <label>
                      <b>Old Name:</b>{" "}
                    </label>
                    <input
                      type="text"
                      name="oldName"
                      disabled
                      value={oldName}
                      id=""
                    />
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>
                          <b>New Name:</b>
                        </label>
                        <input
                          type="text"
                          name="newName"
                          id=""
                          value={newName}
                          onChange={(password) =>
                            setNewName(password.target.value)
                          }
                        />
                      </div>
                    </div>
                    {/* <div class="col-sm">
                            <div class="form-wrapper">
                              <label>Confirm Password</label>
                              <input type="password" name="confirmpass" id="" value={confirmPassword} onChange={(password) =>
                                setConfirmPassword(password.target.value)
                              } />
                            </div>
                          </div> */}
                  </div>
                  {/* <div>
                          <h2 style={{ textAlign: 'center' }}>{errordisp}</h2>
                        </div> */}
                  <div class="mt-4 mb-3">
                    <div class="d-flex justify-content-center">
                      <button
                        class="btn btn-primary "
                        onClick={updateName}
                        style={{ width: "15rem" }}
                      >
                        <b>Update</b>
                      </button>
                    </div>
                    <div>
                      <h2 style={{ textAlign: "center", color: "red" }}>
                        {errordisp}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Name cannot be updated more than once for a given roll
                      number !!!!
                    </h3>
                  </div>
                </div>
              </div>

              <div class="shadow mb-5 rounded-16">
                <div class="p-4">
                  <div class="row">
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>
                          <b>College Name:</b>
                        </label>
                        <input
                          type="text"
                          name="newName"
                          id=""
                          value={sname}
                          onChange={(schoolname) =>
                            setsname(schoolname.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div>
                          <h2 style={{ textAlign: 'center' }}>{errordisp}</h2>
                        </div> */}
                  <div class="mt-4 mb-3">
                    <div class="d-flex justify-content-center">
                      <button
                        class="btn btn-primary "
                        onClick={updateschool}
                        style={{ width: "15rem" }}
                      >
                        <b>Update School Name</b>
                      </button>
                    </div>
                    <div>
                      <h2 style={{ textAlign: "center", color: "red" }}>
                        {sNameMessage}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NameIndigoChange;
