import React, { useState } from "react";
// import { CSVLink } from "react-csv";
// import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
const XLSX = require("xlsx");

const ExportAdminCSV = ({
  fileName = "Customers_Infomation_xlsx",
  Heading = [],
  csvData,
  buttonName,
  type,
}) => {
  console.log("csvDataasasas", csvData);
  // ******** XLSX with object key as header *************
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const exportToCSV = (csvData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(csvData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  // ******** XLSX with new header *************
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [datacsv, setDataCsv] = useState("");
  // Heading = [
  //     {
  //         RollNo: "RollNo",
  //         SubscriberType: "SubscriberType",
  //         PaymentID: "PaymentID",
  //         OrderID: "OrderID",
  //         Name: "Name",
  //         Mobile: "Mobile",
  //         CreatedDate: "CreatedDate",
  //         ModifiedDate: "ModifiedDate",
  //         Amount: "Amount"
  //     }
  // ];

  // const data = [{
  //     "RollNo": "IN220299990002",
  //     "SubscriberType": "Individual",
  //     "PaymentID": "E2207275AN6NFT",
  //     "OrderID": "GOTERI2022_IN220299990002_22114",
  //     "Name": "Keerthana Potta",
  //     "Mobile": "9849860842",
  //     "CreatedDate": "2022-07-27T11:18:00.000Z",
  //     "ModifiedDate": "2022-07-27T11:18:00.000Z",
  //     "Amount": '225'
  // }];

  // useState(() => {

  //     setDataCsv([...csvData]);
  //     console.log("csvData inside", csvData);
  // }, [csvData]);

  const exportToCSV = (fileName) => {
    console.log("csvData>>>", datacsv);
    let wscols = [];
    let ws;
    let wb;
    let excelBuffer;
    let data;
    // let datacsv1 = [{
    //     "Schoolcode_Rollno": "IN220299990002",
    //     "SubscriberType": "Individual",
    //     "PaymentID": "E2207275AN6NFT",
    //     "OrderID": "GOTERI2022_IN220299990002_22114",
    //     "Name": "Keerthana Potta",
    //     "Mobile": "9849860842",
    //     "CreatedDate": "2022-07-27T11:18:00.000Z",
    //     "ModifiedDate": "2022-07-27T11:18:00.000Z",
    //     "Amount": "225"
    // }];

    // const Heading = [
    //     {
    //         Schoolcode_Rollno: "Schoolcode_Rollno",
    //         SubscriberType: "SubscriberType",
    //         PaymentID: "PaymentID",
    //         OrderID: "OrderID",
    //         Name: "Name",
    //         Mobile: "Mobile",
    //         CreatedDate: "CreatedDate",
    //         ModifiedDate: "ModifiedDate",
    //         Amount: "Amount"
    //     }
    // ];

    console.log("Heading", Heading);

    if (type === "successfull") {
      wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws = XLSX.utils.json_to_sheet(Heading, {
        header: [
          "Schoolcode_Rollno",
          "SubscriberType",
          "PaymentID",
          "OrderID",
          "Name",
          "Mobile",
          "CreatedDate",
          "ModifiedDate",
          "Amount",
        ],
        skipHeader: true,
        origin: 0, //ok
      });
      ws["!cols"] = wscols;
      XLSX.utils.sheet_add_json(ws, csvData, {
        header: [
          "Schoolcode_Rollno",
          "SubscriberType",
          "PaymentID",
          "OrderID",
          "Name",
          "Mobile",
          "CreatedDate",
          "ModifiedDate",
          "Amount",
        ],
        skipHeader: true,
        origin: -1, //ok
      });
    } else if (type === "unpaidSchool") {
      wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws = XLSX.utils.json_to_sheet(Heading, {
        header: [
          "SchoolsCode",
          "SchoolName",
          "Mobile",
          "Email",
          "Coordinating_Teacher",
          "Mobile_Coordinator",
          "NoOfUnpaidStudent",
          "Mode",
          "Country",
          "State",
          "District",
          "SubscriberType",
        ],
        skipHeader: true,
        origin: 0, //ok
      });
      ws["!cols"] = wscols;
      XLSX.utils.sheet_add_json(ws, csvData, {
        header: [
          "SchoolsCode",
          "SchoolName",
          "Mobile",
          "Email",
          "Coordinating_Teacher",
          "Mobile_Coordinator",
          "NoOfUnpaidStudent",
          "Mode",
          "Country",
          "State",
          "District",
          "SubscriberType",
        ],
        skipHeader: true,
        origin: -1, //ok
      });
    } else if (type === "paidSchool") {
      wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      ws = XLSX.utils.json_to_sheet(Heading, {
        header: [
          "SchoolsCode",
          "SchoolName",
          "Mobile",
          "Email",
          "Coordinating_Teacher",
          "Mobile_Coordinator",
          "NoOfPaidStudent",
          "Mode",
          "Country",
          "State",
          "District",
          "SubscriberType",
        ],

        skipHeader: true,
        origin: 0, //ok
      });
      ws["!cols"] = wscols;
      XLSX.utils.sheet_add_json(ws, csvData, {
        header: [
          "SchoolsCode",
          "SchoolName",
          "Mobile",
          "Email",
          "Coordinating_Teacher",
          "Mobile_Coordinator",
          "NoOfPaidStudent",
          "Mode",
          "Country",
          "State",
          "District",
          "SubscriberType",
        ],
        skipHeader: true,
        origin: -1, //ok
      });
    } else if (type === "applicationStatus") {
      wscols = [
        { wch: 5 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        // { wch: 15 },
        // { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
      ];
      ws = XLSX.utils.json_to_sheet(Heading, {
        header: [
          "SNO",
          "RollNo",
          "Name",
          "Class",
          "Section",
          // "Exam",
          "ExamDate",
          // "SampleBooklet",
          "Fees",
          "FeesStatus",
          "Marks",
          "Result",
        ],
        skipHeader: true,
        origin: 0, //ok
      });
      ws["!cols"] = wscols;
      XLSX.utils.sheet_add_json(ws, csvData, {
        header: [
          "SNO",
          "RollNo",
          "Name",
          "Class",
          "Section",
          // "Exam",
          "ExamDate",
          // "SampleBooklet",
          "Fees",
          "FeesStatus",
          "Marks",
          "Result",
        ],
        skipHeader: true,
        origin: -1, //ok
      });
    } else if (type === "unpaid" || type === "vwReportSchoolUnpaidWithSlot") {
      wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws = XLSX.utils.json_to_sheet(Heading, {
        header: [
          "SchoolsCode",
          "SubscriberType",
          "SchoolName",
          "Mobile",
          "Email",
          "Mode",
          "Country",
          "State",
          "District",
          "NoOfUnpaidStudent",
        ],
        skipHeader: true,
        origin: 0, //ok
      });
      ws["!cols"] = wscols;
      XLSX.utils.sheet_add_json(ws, csvData, {
        header: [
          "SchoolsCode",
          "SubscriberType",
          "SchoolName",
          "Mobile",
          "Email",
          "Mode",
          "Country",
          "State",
          "District",
          "NoOfUnpaidStudent",
        ],
        skipHeader: true,
        origin: -1, //ok
      });
    } else if (
      type === "vwReportIndividualUnpaidWithSlot" ||
      type === "vwReportIndividualUnpaid"
    ) {
      wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws = XLSX.utils.json_to_sheet(Heading, {
        header: ["RollNo", "SubscriberType", "Name", "Mobile", "Email"],
        skipHeader: true,
        origin: 0, //ok
      });
      ws["!cols"] = wscols;
      XLSX.utils.sheet_add_json(ws, csvData, {
        header: ["RollNo", "SubscriberType", "Name", "Mobile", "Email"],
        skipHeader: true,
        origin: -1, //ok
      });
    }

    wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
    excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="btn btn-primary mb-4 mb-sm-0 w-100"
      onClick={(e) => {
        exportToCSV(fileName);
      }}
    >
      {buttonName}
    </button>

    // <>
    //     <CSVLink data={datacsv} headers={Heading}>
    //         Download me
    //     </CSVLink>;
    // </>
  );
};

export default ExportAdminCSV;

// This component is a presentational component which takes the data to download and file name as props. The exportToCSV method is invoked when the export button is clicked on line 20.
