import React, { useEffect } from "react";
// import Header from "./Header";
import { Link } from "react-router-dom";

import download from "../../assets/icons/download-icon.png";
import schoolpreview from "../../assets/icons/new-school-preview.png";
// import schoolrec from "../../assets/images/school_rec.svg";
// import kid from "../../assets/images/kid.png";
import login from "../../assets/images/login_school.jpeg";
import login_individual from "../../assets/images/login_individual.jpeg";

import computerUser from "../../assets/icons/computer-user.svg";
import youth from "../../assets/icons/youth.svg";
import registration_interForm from "../../assets/pdf/registration_interForm.pdf";
// import "./Home.scss";

export default function Home({ isLogged }) {
  const openins = () => {
    window.open("/Instructions");
  };

  const openLink = () => {
    setTimeout(() => {
      window.open("https://terigo.scoredge.com/tdmweb/sc", "_blank");
    }, 2000);
  };

  return (
    // <div className="container-home">
    //   <div className="announcements-panel">
    //     <h1>Announcements</h1>
    //     <ul className="list">
    //       <li>
    //         <a href="javascript:void(0)">
    //           <img src={download} alt="" />
    //           <span>Slot for Exam</span>
    //         </a>
    //       </li>
    //       <li>
    //         <a href="javascript:void(0)">
    //           <img src={download} alt="" />
    //           <span>Slot for Demo</span>
    //         </a>
    //       </li>
    //       <li>
    //         <a href="javascript:void(0)">
    //           <img src={download} alt="" />
    //           <span>Account Details</span>
    //         </a>
    //       </li>
    //       <li>
    //         <a href="javascript:void(0)">
    //           <img src={download} alt="" />
    //           <span>Page Navigation</span>
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    //   <div className="welcome-section">
    //     <div className="info-content">
    //       <h1>
    //         Welcome to <span>Green Olympiad</span>
    //       </h1>
    //       <div className="login-text">
    //         <h4>Login</h4>
    //         <p>Welcome to Login Page</p>
    //       </div>
    //     </div>
    //     <div className="kid-img">
    //       <img src={kid} alt="" />
    //     </div>
    //   </div>
    //   <div class="login-category-panel">
    //     <div className="login-tab">
    //       <Link to="/school-login">
    //         <div className="tab active">
    //           <div className="img">
    //             <img src={schoolrec} alt="" />
    //           </div>
    //           <div className="info">
    //             <h4 className="title">
    //               School <br /> <span>Registration / Login</span>
    //             </h4>
    //             <p>STD 4-12</p>
    //           </div>
    //         </div>
    //       </Link>
    //       <Link to="/student-login">
    //         <div className="tab">
    //           <div className="img">
    //             <img src={computerUser} alt="" />
    //           </div>
    //           <div className="info">
    //             <h4 className="title">
    //               Individual <br /> <span>Registration / Login</span>
    //             </h4>
    //             <p>STD 4-12</p>
    //           </div>
    //         </div>
    //       </Link>
    //       <Link to="/student-login">
    //         <div className="tab">
    //           <div className="img">
    //             <img src={youth} alt="" />
    //           </div>
    //           <div className="info">
    //             <h4 className="title">
    //               Go4youth <br /> <span>Registration / Login</span>
    //             </h4>
    //             <p>Undergraduate / Post Graduation</p>
    //           </div>
    //         </div>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="dashboard-wraper d-flex">
      {/* <aside className="sidebar" id="sidebar" >
        <div className="sidenav">
          <div className="mobileToggler d-lg-none">
            <a className="navbar-brand" href="#"><img src="images/logo.png" alt="" /></a>
            <button className="navbar-toggler closetogglerbtn" type="button" data-bs-toggle="collapse" data-bs-target="#announce">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </button>
          </div>
          <div className="announce">
            <div className="navtitle">Announcements</div>
            <ul className="list-unstyled">
              <li className="active"><a href="#" onClick={openins}><img src={download} height="18" alt="" />Instructions</a></li>
            
            </ul>
          </div>

        </div>
      </aside> */}
      <main className="content">
        <div className="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
          {/* <div className="welcomebox">
            <div className="row align-items-center">
              <div className="col">
                <marquee>
                  <h1>Welcome to GREEN OLYMPIAD 2023.</h1>
                </marquee>
              </div>
            </div>
          </div> */}

          <div>
            {/* <h2
              style={{
                color: "red",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "2em",
              }}
            >
              Examination Details are Live!
            </h2> */}

            <div className="notificationText1">
              {/* <h2 className="fontBlue">IMPORTANT NOTIFICATION.</h2> */}
              <br />
              {/* <h2
                className="text-center"
                style={{ color: "red", fontWeight: "bold" }}
              >
                Registration for GREEN Olympiad 2023 is closed.
              </h2> */}
              <h1
                className="text-center"
                style={{ color: "#0d6efd", fontWeight: "bold" }}
              >
                Important Information
              </h1>
              <br />
              {/* <h4 style={{ color: "#0d6efd", fontWeight: "bold" }}>
                GREEN Olympiad mock question papers and examination material for
                12 October exam date are dispatched to schools. Kindly check the
                tracking status by logging in at
                <a className="mx-2" href="https://www.terigreenolympiad.com">
                  https://www.terigreenolympiad.com
                </a>
                using your school code and password.
              </h4> */}

              <h2
                style={{
                  color: "#0d6efd",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                GO4Youth 2024
              </h2>

              <h2
                style={{
                  color: "#0d6efd",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                GREEN Olympiad for Youth (GO4Youth) is open for students in age
                group of 18 – 25 years enrolled in Higher Education institutions
                (HEIs) in India
              </h2>

              {/* <br /> */}

              {/* <h3 style={{ color: "#0d6efd", fontWeight: "bold" }}>
                The results will be published in the first week of January 2024
                and thereafter certificates will be sent to each school. We look
                forward to your cooperation.
              </h3> */}
              <br />

              {/* <h4 style={{ color: "#0d6efd", fontWeight: "bold" }}>
                The examination date of 01 September 2023 is changed to 02
                November 2023. All schools who have opted for 01 September 2023
                as their examination date, will now appear for the examination
                on 02 November 2023.
              </h4> */}

              {/* <h2 style={{ color: "red", fontWeight: "bold" }}>
                Please login and download the certificates .
              </h2>
              <h2>
                In case of any query, please call helpdesk at 011-46571473
                between 10 am to 5 pm(India Time).
              </h2> */}
              {/* <h2 className="fontBlue">
                Certificates are available . Please login and download the
                certificates . In case of any query, please call helpdesk at
                011-46571473 (India Time).
              </h2> */}
              {/* <h3 style={{ color: "red", fontWeight: "bold" }}>
                GREEN Olympiad 2023 Registration are closed.
              </h3>
              <h3 style={{ color: "red", fontWeight: "bold" }}>
                Schools who generated their school code and didn’t complete the
                payment, can make the bank transfer and secure their
                registration.
              </h3> */}
              {/* <h3 style={{ color: "red", fontWeight: "bold" }}>
                GREEN Olympiad is accepting only school registration this year,
                with a minimum of 20 students. Individual students / Parents are
                requested to get in touch with your respective schools.
              </h3> */}
              <br />
              {/* <h4 style={{ color: "blue", fontWeight: "bold" }}>
                Notification Alert about change in GREEN Olympiad 2023
                Examination Date{" "}
              </h4>
              <h4 style={{ color: "blue", fontWeight: "bold" }}>
                The examination date of 01 September 2023 is changed to 02
                November 2023. This is done to ensure timely receipt of
                examination material by schools.{" "}
              </h4>

              <h4 style={{ color: "blue", fontWeight: "bold" }}>
                All schools who have opted for 01 September 2023 as their
                examination date, will now appear for the examination on 02
                November 2023.
              </h4> */}

              {/* <h4
                style={{
                  color: "blue",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                The examination scheduled for 12 October 2023, remains
                unchanged.
              </h4>
              <h4
                className="blink_me text-center"
                style={{ color: "green", fontWeight: "bold" }}
              > */}
              {/* Last Date of Registration has been extended till 31-July */}
              {/* </h4> */}
              {/* <h2>
                For final schedule, check your registered email id and updates
                posted on{" "}
                <a href="https://teriin.org/olympiad">
                  https://teriin.org/olympiad
                </a>{" "}
                <a href=" https://terigreenolympiad.com">
                  https://terigreenolympiad.com
                </a>
              </h2> */}

              {/* <div className="imgTextContainer">
                {" "}
                <h4 style={{ fontWeight: "bold" }}>
                  <a href="https://teriin.org/olympiad">
                    https://teriin.org/olympiad
                  </a>
                </h4>
                <h4 style={{ fontWeight: "bold" }}>
                  {" "}
                  <a href=" https://terigreenolympiad.com">
                    https://terigreenolympiad.com
                  </a>
                </h4>
              </div> */}
              {/* <h2 className="fontBlue">
                Kindly download your username and password from this site for
                appearing in the examination as per the following steps. 
                <br />
                Step 1:
                <p>
                  A: For school registration (click on left hand box below), use
                  your allotted school code, and password.
                </p>
                <p>
                  B: For individual registration (click on the box provided in
                  the middle), use your allotted roll number, and password.
                </p>
              </h2>

              <h2 className="fontBlue">
                Step 2:
                <br />
                Avail examination details, provided in the left-hand bar, after
                logging in as per above details.
                <p>
                  Please note that your roll number is the same as the username
                  that you need to enter in the examination portal.
                </p>
                <p>
                  Please login at least 5-10 minutes before the start of exam.
                </p>
                <p>For any clarifications, call at +91-11-46571473.</p>
              </h2> */}
            </div>
            {/* <div className="imgContainer"> */}
            {/* uncomennt below div for left image */}
            {/* <div
                style={{
                  backgroundImage: `url("${login}")`,
                }}
                className="notificationText1 loginImg"
              ></div> */}

            {/* <h2 className="fontBold">
                Instructions for logging in GREEN Olympiad Mock / Examination
                Portal
              </h2>
              <h2 className="fontBold">
                1. In case, candidate is not able to login then he /she need to
                try again after 5 minutes.
              </h2>
              <br />
              <h2 className="fontBold">
                2.In case candidate get's logged out or closes the tab then
                he/she should try login again after 5 minutes.
              </h2>
              <br />
              <h2 className="fontBold">
                3. Candidates can login multiple times, till their exam is
                submitted. Full time will be allotted to complete the exam.
              </h2>
              <br />
              <h2 className="fontBold">
                {" "}
                4. After completion of exam, every candidate needs to click on
                ‘submit’ for completing the exam.
              </h2> */}
            {/* </div> */}

            {/* uncomennt below div for right image */}
            {/* <div
                style={{
                  backgroundImage: `url("${login_individual}")`,
                }}
                className="notificationText1 loginImg"
              ></div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <div className="imgTextContainer">
              {" "}
              <h4 style={{ fontWeight: "bold" }}>
                School student Login Example
              </h4>
              <h4 style={{ fontWeight: "bold" }}>Individual Login Example</h4>
            </div> */}

            {/* uncomennt below div for exam start link */}
            {/* <div className="notificationText">
              <h3 className="font-bold notificationTextContent">
                <a
                  onClick={openLink}
                  href="javascript:void(0)"
                  style={{
                    textDecoration: "none",
                    fontSize: "27px",
                    color: " #fff",
                  }}
                >
                  Click Here to Begin GO Examination
                </a>
              </h3>
            </div> */}
            {/* <h3
                style={{ fontWeight: "bold", color: "red", fontSize: "25px" }}
              >
                Due to a technical failure, GREEN Olympiad examination will
                begin at 11 AM. Kindly log in at any time between 11 AM - 9 PM
                today to appear for the examination. Inconvenience is deeply
                regretted.
              </h3> */}

            {/* <h4 className="font-bold" style={{ marginBottom: "12px" }}>
                Thank you for registering for GREEN Olympiad / GO4Youth 2022.
                Please log in using your school code/roll number and respective
                password. Kindly click on ‘Exam Details’ provided in the
                left-hand bar for availing the instructions for the examination
                and details of students (such as roll numbers, password,
                selected theme, date, time slot, etc.).
              </h4>

              <h4 className="font-bold" style={{ marginBottom: "12px" }}>
                In case of any query, please call the helpdesk at 011-46571473
                from Monday to Saturday between 10 am to 5 pm (IST).
              </h4>

              <h4 className="font-bold">Sincere regards</h4>
              <h4 className="font-bold">GREEN Olympiad Secretariat</h4> */}
            {/* </div> */}
          </div>

          {/* <div>
            <h3
              style={{
                color: "blue",
                textAlign: "left",
                fontWeight: "bold",
                marginTop: "3em",
              }}
            >
              Schools who have registered online and have made a bank transfer
              for registration fee, kindly email us your school code,transaction
              id ,<br /> amount transferred and date of transfer on
              go@teri.res.in
            </h3>
          </div> */}
          <div className="row gx-sm-5 dashboard-row my-4 my-lg-5 py-md-4 justify-content-center school-login">
            {/* <div className="col-md-4 item text-center px-0">
              <Link to="/school-login" className="box">
               
                <img src={schoolpreview} alt="" />
                <h4>Login for GREEN Olympiad Registeration</h4>
                
                <p>STD 4-12</p>
               
              </Link>
            </div> */}

            {/* <div className="col-md-4 item text-center">
              <Link to="/student-login" className="box">
                <img src={schoolpreview} alt="" />
                <h4>Login for Individual Registration</h4>

                <p>STD 4-12</p>
              </Link>
            </div> */}

            <div className="col-md-4 item text-center">
              <Link to="/student-Inlogin" className="box">
                <img src={schoolpreview} alt="" />

                <h4>Login for GO4Youth Registration</h4>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
