import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL, API_END_POINTS } from "../../apis/api";
import AdminSidebar from "../main/adminSidebar";
import ExportAdminCSV from "./adminExcelDownload";

function DownloadReport({ isAdmin }) {
  const [vwReportOnlineSucessfullPayment, setVwReportOnlineSucessfullPayment] =
    useState([]);
  const [vwReportSchoolUnpaid, setVwReportSchoolUnpaid] = useState([]);
  const [vwReportSchoolPaid, setVwReportSchoolPaid] = useState([]);
  const [vwReportSchoolUnpaidWithSlot, setVwReportSchoolUnpaidWithSlot] =
    useState([]);

  const [
    vwReportIndividualUnpaidWithSlot,
    setVwReportIndividualUnpaidWithSlot,
  ] = useState([]);
  const [vwReportIndividualUnpaid, setVwReportIndividualUnpaid] = useState([]);

  const headers = [
    {
      Schoolcode_Rollno: "Schoolcode_Rollno",
      SubscriberType: "SubscriberType",
      PaymentID: "PaymentID",
      OrderID: "OrderID",
      Name: "Name",
      Mobile: "Mobile",
      CreatedDate: "CreatedDate",
      ModifiedDate: "ModifiedDate",
      Amount: "Amount",
    },
  ];

  const vwReportSchoolUnpaidSlotHeaders = [
    {
      SchoolsCode: "SchoolsCode",
      SubscriberType: "SubscriberType",
      SchoolName: "SchoolName",
      Mobile: "Mobile",
      Email: "Email",
      NoOfUnpaidStudent: "NoOfUnpaidStudent",
    },
  ];

  const vwReportSchoolUnpaidHeaders = [
    {
      SchoolsCode: "SchoolsCode",
      SchoolName: "SchoolName",
      Mobile: "Mobile",
      Email: "Email",
      Coordinating_Teacher: "Coordinating_Teacher",
      Mobile_Coordinator: "Mobile_Coordinator",
      NoOfUnpaidStudent: "NoOfUnpaidStudent",
      Mode: "Mode",
      Country: "Country",
      State: "State",
      District: "District",
      SubscriberType: "SubscriberType",
    },
  ];

  const vwReportSchoolPaidHeaders = [
    {
      SchoolsCode: "SchoolsCode",
      SchoolName: "SchoolName",
      Mobile: "Mobile",
      Email: "Email",
      Coordinating_Teacher: "Coordinating_Teacher",
      Mobile_Coordinator: "Mobile_Coordinator",
      NoOfUnpaidStudent: "NoOfUnpaidStudent",
      NoOfPaidStudent: "NoOfPaidStudent",
      Mode: "Mode",
      Country: "Country",
      State: "State",
      District: "District",
      SubscriberType: "SubscriberType",
    },
  ];

  const vwReportIndividualUnpaidWithSlotHeaders = [
    {
      RollNo: "RollNo",
      SubscriberType: "SubscriberType",
      Name: "Name",
      Mobile: "Mobile",
      Email: "Email",
    },
  ];

  useEffect(() => {
    const download = async () => {
      const reportData = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.vwReportOnlineSucessfullPayment}`
      );
      setVwReportOnlineSucessfullPayment([...reportData.data.data]);

      const vwReportSchoolUnpaidData = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.vwReportSchoolUnpaid}`
      );
      setVwReportSchoolUnpaid(vwReportSchoolUnpaidData.data.data);

      const vwReportSchoolUnpaidWithSlotData = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.vwReportSchoolUnpaidWithSlot}`
      );
      setVwReportSchoolUnpaidWithSlot(
        vwReportSchoolUnpaidWithSlotData.data.data
      );

      const vwReportIndividualUnpaidWithSlotData = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.vwReportIndividualUnpaidWithSlot}`
      );
      setVwReportIndividualUnpaidWithSlot(
        vwReportIndividualUnpaidWithSlotData.data.data
      );

      const vwReportSchoolPaidData = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.vwReportSchoolPaid}`
      );
      console.log("vwReportSchoolPaidData", vwReportSchoolPaidData);
      setVwReportSchoolPaid(vwReportSchoolPaidData.data.data);

      const vwReportIndividualUnpaidData = await axios.get(
        `${API_BASE_URL}${API_END_POINTS.vwReportIndividualUnpaid}`
      );
      setVwReportIndividualUnpaid(vwReportIndividualUnpaidData.data.data);
    };
    download();
    return () => {
      setVwReportOnlineSucessfullPayment([]);
      setVwReportSchoolUnpaid([]);
      setVwReportSchoolUnpaidWithSlot([]);
      setVwReportIndividualUnpaidWithSlot([]);
      setVwReportIndividualUnpaid([]);
      setVwReportSchoolPaid([]);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-3">
          {/* side bar will come here */}
          <AdminSidebar isAdmin={isAdmin} />
        </div>
        <div className="col-lg-9 ">
          <main className="content ">
            <div className="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
              <div className="section-title mb-4 text-muted">
                <h6 className="font-bold ">
                  Download Reports Related Information
                </h6>
                {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
              </div>
            </div>

            <div class="shadow bg-white rounded-16">
              <div class="p-4 ">
                <div class="row">
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>vwReportOnlineSucessfullPayment</label>
                      <ExportAdminCSV
                        csvData={vwReportOnlineSucessfullPayment}
                        fileName="Online Sucessfull_Payment_(Payment Gateway)"
                        type="successfull"
                        Heading={headers}
                        buttonName="Online Sucessfull Payment Payment Gateway"
                      />
                    </div>
                  </div>

                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>vwReportSchoolUnpaid</label>
                      <ExportAdminCSV
                        csvData={vwReportSchoolUnpaid}
                        fileName="School_Unpaid_Total"
                        type="unpaidSchool"
                        Heading={vwReportSchoolUnpaidHeaders}
                        buttonName="School Unpaid Total"
                      />
                    </div>
                  </div>

                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>vwReportSchoolPaid</label>
                      <ExportAdminCSV
                        csvData={vwReportSchoolPaid}
                        fileName="School_paid_Total"
                        type="paidSchool"
                        Heading={vwReportSchoolPaidHeaders}
                        buttonName="School Paid Total"
                      />
                    </div>
                  </div>

                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>vwReportSchoolUnpaidWithSlot</label>
                      <ExportAdminCSV
                        csvData={vwReportSchoolUnpaidWithSlot}
                        fileName="School_Unpaid_with_slots"
                        type="vwReportSchoolUnpaidWithSlot"
                        Heading={vwReportSchoolUnpaidSlotHeaders}
                        buttonName="School Unpaid with slots"
                      />
                    </div>
                  </div>

                  {/* <div class="col-sm">
                                        <div class="form-wrapper">
                                            <label>vwReportIndividualUnpaidWithSlot</label>
                                            <ExportAdminCSV csvData={vwReportIndividualUnpaidWithSlot} fileName="Individual_Unpaid_with_slots" type="vwReportIndividualUnpaidWithSlot" Heading={vwReportIndividualUnpaidWithSlotHeaders} buttonName="Individual Unpaid with slots" />
                                        </div>
                                    </div>



                                    <div class="col-sm">
                                        <div class="form-wrapper">
                                            <label>vwReportIndividualUnpaid</label>
                                            <ExportAdminCSV csvData={vwReportIndividualUnpaid} fileName="Individual_Unpaid_Total" type="vwReportIndividualUnpaid" Heading={vwReportIndividualUnpaidWithSlotHeaders} buttonName="Individual Unpaid Total" />
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default DownloadReport;
