import React, { useEffect, useState } from 'react'
import { API_BASE_JAVA_URL, API_BASE_URL, API_END_POINTS } from '../../apis/api';
import AdminSidebar from '../main/adminSidebar'
import axios from "axios";
import uploadfiles from "../../assets/icons/upload_file_green.svg";
import * as xlsx from 'xlsx';

function UpdatePayment({ isAdmin }) {

    const [paymentData, setPaymentData] = useState([]);
    const [fileName, setFileName] = useState([]);
    const [file, setFile] = useState([]);
    const [message, setMessage] = useState([]);
    const [exceljsondata, setExceljsondata] = useState([]);


    const onFileChange = (event) => {
        // this.wrongFile = ""

        if (event.target.files.length > 0) {
            let file = event.target.files[0];
            setFile(file);
            // this.fileName = file.name;
            setFileName(file.name);
            validateUploadFile(file);
        }


    }

    const updatePayment = async () => {
        let updatePaymentData = [...exceljsondata];
        const updatePaymentDetails = await axios.post(`${API_BASE_JAVA_URL}${API_END_POINTS.updatePaymentDetails}`, updatePaymentData);
        setMessage(updatePaymentDetails.data);
    }

    const validateUploadFile = (fileExcel) => {
        // this.data = ""
        // if (this.fileName !== undefined && this.fileName.toUpperCase().startsWith('ROLLNO')) {
        //   this.wrongFile = ""


        // }
        // else {
        // //  console.log("Wrong File uploaded")
        //   this.wrongFile = "Wrong File"
        //   return;
        // }

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            let arrayBuffer = fileReader.result;
            var data = new Uint8Array(arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = xlsx.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            let exceldata = xlsx.utils.sheet_to_json(worksheet, { raw: true, defval: "" });
            setExceljsondata([...exceldata]);

            // console.log(this.exceljsondata);
            // this.providerservice.importexcel(this.exceljsondata).subscribe(data=>{
            // })
        }
        if (fileExcel !== "") {
            fileReader.readAsArrayBuffer(fileExcel);
        }



    }



    return (

        <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-3">
                    {/* side bar will come here */}
                    <AdminSidebar isAdmin={isAdmin} />
                </div>
                <div className="col-lg-9 ">
                    <main className="content ">
                        <div className="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
                            <div className="section-title mb-4 text-muted">
                                <h6 className="font-bold ">Update Payments Information</h6>
                                {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                            </div>
                            <div className="col-sm-7 mb-5 mb-sm-0">
                                <div className="upload-box text-center h-100">
                                    <label>
                                        {/* <input type="file" name="upload" accept=".xlsx" /> */}
                                        <input
                                            className="upload"
                                            type="file"
                                            placeholder="Name"
                                            name="uname"
                                            required
                                            onChange={onFileChange}
                                        />
                                        <img src={uploadfiles} alt="" />
                                        <br />
                                        <strong>Choose excel</strong>
                                        <h5>{fileName}</h5>
                                    </label>

                                </div>
                            </div>
                            <div className="btnmain bulkemail">
                                <a>
                                    <button className="btn btn-primary" onClick={updatePayment}>
                                        Update Payments
                                    </button>
                                </a>
                            </div>
                            <div>
                                <h2>{message}</h2>
                            </div>


                            <div>

                            </div>
                        </div>

                        <div className="shadow bg-white p-3 rounded-16">

                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default UpdatePayment