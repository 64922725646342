import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import schoolimg from "../../assets/icons/school.png";
import "../../assets/css/style_new.css";
import { Colors } from "../../assets/css/color";
import jwt_decode from "jwt-decode";
import "./SchoolPayment.scss";
import Sidebar from "../main/sidebar";
import {
  API_ADMIN_URL_2,
  REGISTER_API,
  API_BASE_URL,
  API_END_POINTS,
  API_BASE_JAVA_URL,
} from "../../apis/api";
import { notify } from "../../Utills";
import { useNavigate } from "react-router";

import axios from "axios";
import { StudentDataContext } from "../context/datacontext";

export default function SchoolPayment() {
  let paymentData = {};
  let tMockStu = 0;
  let mockFee = 0;
  let totalThemeExamPay = 0;
  let currency = "";
  let decodedSchoolData = {};
  let mockTotal = 0;

  // let tFee = 0;
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [utrValidateMsg, setUtrValidateMsg] = useState("");
  const [utr, setutr] = useState(0);
  const { state, dispatch } = useContext(StudentDataContext);
  const [tFee, setTFee] = useState(0);
  const [err, setErr] = useState("");

  useEffect(() => {
    const getPaymentData = async () => {
      const isPaymentAllowed = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.ispaymentallowed}`,
        {
          schoolCode: state.school_code,
        }
      );

      console.log("isPaymentAllowed", isPaymentAllowed);

      if (isPaymentAllowed?.status === 200) {
        if (isPaymentAllowed.data.data.TotalStudent > 0) {
          // complete the slot allocation first

          if (isPaymentAllowed.data.data.PendingSlotCount === 0) {
            if (isPaymentAllowed.data.data.PendingPaymentCount > 0) {
              const paymentDetails = await axios.post(
                `${API_BASE_URL}${API_END_POINTS.getpaymentdetails}`,
                {
                  school_code: state.school_code,
                }
              );
              // const paymentDetails = await axios.post(`${API_END_POINTS.getpaymentdetails}`, {
              //   school_code: state.school_code
              // }
              // );
              try {
                console.log("paymentDetails", paymentDetails);
                if (paymentDetails?.status === 200) {
                  let totalFees = 0;
                  console.log("paymentDetails", paymentDetails);
                  for (let i = 0; i < paymentDetails.data.data.length; i++) {
                    // if (paymentDetails.data.data[i]['ExamTheme'] === 'MOCK') {
                    totalFees += paymentDetails.data.data[i]?.Fee;
                    // }
                  }
                  setTFee(totalFees);

                  setPaymentStatus(paymentDetails.data.data);
                } else {
                  setPaymentStatus([]);
                  setErr("something went wrong");
                }
              } catch (e) {
                console.log("error");
              }
            } else {
              setErr(`Payment for all the students has been done`);
            }
          } else {
            setErr(
              `Examination Date selection is pending for ${isPaymentAllowed.data.data.PendingSlotCount} students. `
            );
          }
        } else {
          setErr(`There are no students uploaded!!!`);
        }
      }
    };
    getPaymentData();
  }, []);

  try {
    // paymentData = JSON.parse(localStorage.getItem('payment') ?? '[]');
    console.log("paymentStatus", paymentStatus);
    // for (let i = 0; i < paymentStatus.length; i++) {
    //   // if (paymentStatus[i]['ExamTheme'] === 'MOCK') {
    //   tFee += paymentStatus[i]?.Fee;
    //   // }
    // }
    // mockFee = paymentStatus[0]?.mockfee;
    // tFee = paymentStatus.reduce((acc, t) => t.Fee + acc, 0);
    console.log("tFee", tFee);
    // totalThemeExamPay = paymentStatus.reduce((acc, el) => el.ExamTheme * el.StudentCount + acc, 0);
    // mockTotal = tMockStu * mockFee;

    console.log("total mocak", +tMockStu);
  } catch (e) {}

  try {
    // const userToken = localStorage.getItem("token") ? localStorage.getItem("token") : "";
    // let token = userToken;
    // let decodedSchoolData = token !== "" ? jwt_decode(token) : {};

    let decodedSchoolData = { ...state };

    currency = decodedSchoolData.country === "India" ? "INR" : "$";
    console.log("decodedSchoolData", decodedSchoolData);
  } catch (e) {
    console.log("e", e);
  }
  // try {
  //   const userToken = localStorage.getItem("token") ? localStorage.getItem("token") : "";
  //   let token = userToken;
  //   decodedSchoolData = token !== "" ? jwt_decode(token) : {};

  // } catch (e) {

  // }

  const makeOfflinePayment = async (e) => {
    e.preventDefault();

    let newDate = new Date();
    let currentMonth = newDate.getMonth() + 1;
    let currentMonthValue =
      currentMonth.toString().length === 1
        ? `0${currentMonth}`
        : `${currentMonth}`;
    let currentDate = newDate.getDate();
    let currentDateValue =
      currentDate.toString().length === 1
        ? `0${currentDate}`
        : `${currentDate}`;
    let paymentId = `TERI${currentMonthValue}${currentDateValue}`;

    let paymentinsertrecordsObj = {
      schoolcode_Rollno: state?.school_code,
      mode: "OFFLINE",
      orderId: `${state?.school_code}_${paymentId}`,
      amount: `${tFee}.0`,
      subscriberType: "SCHOOL",
      paymentId: paymentId,
      paymentReceivedStatus: "success",
      createdBy: state?.school_code,
      modifyBy: state?.school_code,
    };
    console.log("paymentinsertrecordsObj", paymentinsertrecordsObj);
    let offlinepaymentupdation = await axios.post(
      `${API_BASE_JAVA_URL}${API_END_POINTS.insertPaymentDetailsForOffline}`,
      [paymentinsertrecordsObj]
    );
    console.log("offlinepaymentupdation", offlinepaymentupdation);
    if (offlinepaymentupdation?.status === 200) {
      navigate("/school-application-status");
      // } else {
      //   setErr(payment?.data?.data?.data);
    }
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    let SCHOOLID = decodedSchoolData?.school_code;
    let paymentStatus = await axios.post(
      `${API_BASE_URL}${API_END_POINTS.updatePaymentStatus}`,
      { SchoolID: decodedSchoolData?.school_code }
    );
    //let paymentStatus = await axios.post(`${API_END_POINTS.updatePaymentStatus}`, { SchoolID: decodedSchoolData?.school_code });
    if (paymentStatus && paymentStatus.data.status) {
      notify(`Studant payment status changed!.`, true);
      // navigate("/school-slot");
    } else {
      notify(`payment updation Failed!.`, false);
    }
  };

  const makePayment = async () => {
    if (state?.country !== "India") {
      console.log("utr", utr);
      if (utr === "" || utr === 0) {
        setUtrValidateMsg("Please enter UTR Number");
        return;
      } else {
        setUtrValidateMsg("");
      }

      let obj = {
        amount: tFee,
        idval: state.school_code,
      };

      const payment = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.internationalCashPayment}`,
        obj
      );

      let paymentinsertrecordsObj = {
        schoolcode_Rollno: state?.school_code,
        mode: "ONLINE",
        subscriberType: "SCHOOL",
        paymentId: utr,
        amount: tFee,
        orderId: `${state?.school_code}_${utr}`,
        paymentReceivedStatus: "pending",
        createdBy: state?.school_code,
        modifyBy: state?.school_code,
        ...payment.data.data,
      };
      const paymentinsertrecords = await axios.post(
        `${API_BASE_JAVA_URL}${API_END_POINTS.insertPaymentDetails}`,
        paymentinsertrecordsObj
      );

      console.log("paymentinsertrecords", paymentinsertrecords);
      if (paymentinsertrecords?.status === 200) {
        navigate("/school-application-status");
      }
    } else {
      let obj = {
        type: "SCHOOL",
        // amount: totalThemeExamPay + tMockStu * mockFee,
        amount: tFee,
        idval: state.school_code,
        email: state.email,
        phone: "",
        name: state.schoolname,
        productinfo: state.school_code,
      };

      if (state?.country === "India") {
        obj.phone = state.mobile;
      } else {
        obj.phone = "9818542645";
      }

      const payment = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.payment}`,
        obj
      );
      //const payment = await axios.get(`${API_END_POINTS.payment}`);

      if (payment?.data?.status === 200) {
        // navigate("/school-slot");
        // console.log("payment", payment);
        navigate("/school-application-status");
        window.open(payment.data.url, "_blank");
        let paymentinsertrecordsObj = {
          schoolcode_Rollno: state?.school_code,
          mode: "ONLINE",
          subscriberType: "SCHOOL",
          paymentId: "",
          paymentReceivedStatus: "pending",
          createdBy: state?.school_code,
          modifyBy: state?.school_code,
          ...payment.data.data,
        };

        const paymentinsertrecords = await axios.post(
          `${API_BASE_JAVA_URL}${API_END_POINTS.insertPaymentDetails}`,
          paymentinsertrecordsObj
        );
      } else {
        setErr(payment?.data?.data?.data);
      }
    }
  };
  return (
    <div className="row ">
      <div className="col-lg-3">
        <Sidebar mode={state?.mode} />
      </div>

      <div className="col-lg-9 ">
        <div
          className={`make-payment p-5 pb-50-xs ${
            err.length > 0 ? "hidepayment" : "showpayment"
          }`}
        >
          <div class="section-title mb-4 text-muted">
            <h6 class="font-bold ">Submit registration fee</h6>
            {/* <p>Pay after click make payment button</p> */}
          </div>
          <div className="shadow-lg p-4 bg-body rounded">
            <div className="table-bordered">
              <table className="table">
                <tbody className="blank-tbody">
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
                <tbody>
                  {paymentStatus?.map((theme, i) => {
                    return (
                      <>
                        <tr>
                          <td>
                            {/* Total candidate who opted{" "}
                            <b>
                              {theme?.ExamTheme === "MOCK"
                                ? "Sample Question Booklet"
                                : theme?.ExamTheme}
                            </b> */}
                            <b>
                              {theme?.ExamTheme === "MOCK"
                                ? "Total candidates opted to buy preparatory material"
                                : "Total candidates for GREEN Olympiad Registration"}
                            </b>
                          </td>
                          <td>{theme?.StudentCount}</td>
                        </tr>
                        <tr>
                          <td>Amount</td>
                          <td>
                            {currency}
                            {theme?.Fee}
                          </td>
                        </tr>
                        <tbody className="blank-tbody">
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
                </tbody>
                <tbody className="blank-tbody">
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td>Total Amount</td>
                    <td>
                      {currency} {tFee}.
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div>
                <h3 style={{ textAlign: "center", color: "red" }}>{err}</h3>
              </div>
            </div>

            {/* {state?.mode === 'OFFLINE' && (
              <>
                <div class="row">

                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>Payment Id</label>
                      <input type="text" placeholder="DD Number" value={paymentId} name="paymentId" onChange={e => {
                        setPaymentId(e.target.value);
                      }}
                        required="" />
                    </div>
                  </div>

                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>Amount</label>
                      <input type="text" placeholder="Amount" value={amount} name="amount" onChange={e => {
                        setAmount(e.target.value);
                      }}
                        required="" />
                    </div>
                  </div>

                </div>


                <div class="row">
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>Payment Date</label>
                      <input type="date" placeholder="choose date" value={datePopulate} onChange={e => {
                        console.log("datePopulate", e.target.value);
                        let newdate = dayjs(new Date(e.target.value)).format('DD-MM-YYYY');
                        setDOB(newdate);
                        setDatePopulate(e.target.value);
                      }}
                        name="dob" required="" />
                    </div>
                  </div>
                  <div className="btnmain">
                    <a> <button className="btn btn-primary" onClick={makeOfflinePayment}>
                      Save & Proceed
                    </button></a>
                  </div>
                </div>
              </>
            )} */}
          </div>

          {state?.country !== "India" && err.length === 0 && (
            <>
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                <h5>
                  Schools located outside India, will pay the registration fee
                  via bank transfer.Kindly mention UTR No. in the space given
                  below{" "}
                </h5>
                <br />
              </div>
              <div className="paymentErr">
                <div style={{ textAlign: "center", color: "red" }}>
                  {/* <h2>
                    All international schools / individual students are advised
                    to pay the aforementioned registration fees via bank
                    transfer (NEFT / RTGS) to the following bank account.
                  </h2> */}
                  <h3>
                    Confirmation of registration will be updated on the portal,
                    once we reconcile the payment from the bank. Your payment
                    will be updated in 5-7 days
                  </h3>
                </div>

                <div className="p-4 ">
                  <div className="row paymentTable">
                    <div className="col">
                      <div className="form-wrapper accInf">
                        <div className="accInfSubDiv">
                          <h3>Name of the A/c Holder</h3>
                        </div>
                        <div className="accInfSubDiv">
                          <h3>The Energy and Resources Institute</h3>
                        </div>
                      </div>
                      <hr />
                      <div className="form-wrapper accInf">
                        <div className="accInfSubDiv">
                          <h3>Account Number</h3>
                        </div>
                        <div className="accInfSubDiv">
                          <h3>001694600001134</h3>
                        </div>
                      </div>
                      <hr />
                      <div className="form-wrapper accInf">
                        <div className="accInfSubDiv">
                          <h3>Name of the Bank</h3>
                        </div>
                        <div className="accInfSubDiv">
                          <h3>Yes Bank</h3>
                        </div>
                      </div>
                      <hr />
                      <div className="form-wrapper accInf">
                        <div className="accInfSubDiv">
                          <h3>Full address of the bank</h3>
                        </div>
                        <div className="accInfSubDiv">
                          <h3>
                            D-12,South Extension, Part 2 New Delhi-110049, India
                          </h3>
                        </div>
                      </div>
                      <hr />
                      <div className="form-wrapper accInf">
                        <div className="accInfSubDiv">
                          <h3>Swift Code</h3>
                        </div>
                        <div className="accInfSubDiv">
                          <h3>YESBINBBDEL</h3>
                        </div>
                      </div>
                      <hr />
                      <div className="form-wrapper accInf">
                        <div className="accInfSubDiv">
                          <h3>IFSC Code:</h3>
                        </div>
                        <div className="accInfSubDiv">
                          <h3>YESB0000016</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {state?.country !== "India" && (
            <div className="form-wrapper ">
              <label>
                UTR No:<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                placeholder="UTR Number"
                name="UTR"
                // required
                onChange={(utrValue) => {
                  if (utrValue.target.value === "") {
                    setUtrValidateMsg("Please enter UTR Number");
                  } else {
                    setUtrValidateMsg("");
                    setutr(utrValue.target.value);
                  }
                }}
              />
            </div>
          )}

          <div>
            <h5 style={{ color: "red" }}>{utrValidateMsg}</h5>
          </div>
          <div className="btnmain">
            {state?.mode === "ONLINE" && (
              // <a> <button className={`btn btn-primary ${state?.country !== 'India' ? 'disablePaymentBtn' : ''}`} onClick={makePayment}>
              //   Save & Proceed
              // </button></a>

              <a>
                {" "}
                <button className={`btn btn-primary`} onClick={makePayment}>
                  Save & Proceed
                </button>
              </a>
            )}

            {state?.mode === "OFFLINE" && (
              // <a> <button className={`btn btn-primary ${state?.country !== 'India' ? 'disablePaymentBtn' : ''}`} onClick={makePayment}>
              //   Save & Proceed
              // </button></a>

              <a>
                {" "}
                <button
                  className={`btn btn-primary`}
                  onClick={makeOfflinePayment}
                >
                  Insert Payment
                </button>
              </a>
            )}
          </div>
        </div>

        <div className="paymentErr">
          <h2 style={{ textAlign: "center", color: "red" }}>{err}</h2>
        </div>
      </div>
    </div>
  );
}
