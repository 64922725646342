import React, { useContext } from "react";
import { Link } from "react-router-dom";
import schoolimg from "../../assets/icons/school.png";
import uploadfiles from "../../assets/icons/upload_file_green.svg";
import { Colors } from "../../assets/css/color";
import "../../assets/icons/common.svg";
import {
  API_BASE_URL,
  API_END_POINTS,
  API_BASE_JAVA_URL,
} from "../../apis/api";
import { ExcelDateToJSDate, notify, checkRowDuplicacy } from "../../Utills";
import { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router";
import Sidebar from "../main/sidebar";
import { StudentDataContext } from "../context/datacontext";
import ExportCSV from "../main/excelDownload";
var md5 = require("md5");

const XLSX = require("xlsx");
const dayjs = require("dayjs");

function processExcel(data) {
  var workbook = XLSX.read(data, {
    type: "binary",
  });

  var firstSheet = workbook.SheetNames[0];
  var data = to_json(workbook);
  return data;
}

function to_json(workbook) {
  var result = {};
  workbook.SheetNames.forEach(function (sheetName) {
    var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 1,
    });
    if (roa.length) result[sheetName] = roa;
  });
  return JSON.stringify(result, 2, 2);
}

export default function SchoolUploadData() {
  const { state, dispatch } = useContext(StudentDataContext);
  const [file, setFile] = useState(null);
  const [datePopulate, setDatePopulate] = useState("");
  const [studantData, setStudanntData] = useState([]);
  const [duplicateRows, setDuplicateRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const UPLOAD_ENDPOINT = API_END_POINTS.uploadStudantdata;
  const navigate = useNavigate();
  const [stName, setStName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [stDOB, setDOB] = useState("");
  const [stClass, setClass] = useState("4");
  const [stSection, setSection] = useState("");
  const [examTheme, setexamTheme] = useState("ESD");
  const [demoExam, setDemoExam] = useState("NO");
  const [language, setLanguage] = useState("ENGLISH");
  const [idCount, setIdCount] = useState(1);
  const classesdropdown = [4, 5, 6, 7, 8, 9, 10, 11, 12];
  const examThemedropdown = ["ESD"];
  const [filename, setFilename] = useState("");
  const [minRecordLimit, setMinRecordsLimit] = useState(0);
  const [dupRecords, setDupRecords] = useState([]);
  const [dupErr, setDupErr] = useState("");
  const [showArr, setShowArr] = useState([]);
  const userToken = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  let token = userToken;
  // let decodedSchoolData = token !== "" ? jwt_decode(token) : {};

  let decodedSchoolData = { ...state };

  // const checkStudentCount = () => {
  //   axios
  //     .post(`${API_BASE_URL}${API_END_POINTS?.isStudentUploadMax}`, {
  //       school_code: state?.school_code
  //     })
  //     // .post(`${API_END_POINTS?.updateShoolData}`, editschooloption)
  //     .then((res) => {
  //       console.log("hhhhhhh", res.data);
  //       setRecPresent(res.data.data.count);
  //       if (res.data.data.count >= 20) {
  //         setMinRecordsLimit(0);
  //       } else {
  //         setMinRecordsLimit(20);
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const getAppStatus = async () => {
    const appStatus = await axios.post(
      `${API_BASE_URL}${API_END_POINTS.applicationStatus}`,
      {
        school_code: state.school_code,
      }
    );

    if (appStatus?.status === 200) {
      // let idVal = JSON.parse(JSON.stringify(idCount));
      let idVal = 1;
      let finalArr = [];
      let existingdata = appStatus.data.data;

      for (let i = 0; i < existingdata.length; i++) {
        let resultset = {};
        let invalidDate = false;
        const regex = /^\d{2}-\d{2}-\d{4}$/;

        resultset["id"] = idVal++;
        resultset["name"] = existingdata[i]["Name"];
        // if (existingdata[i]["DOB"].match(regex) === null) {
        //   resultset["dob"] = existingdata[i]["DOB"]
        //     .split("-")
        //     .reverse()
        //     .join("-");
        // } else {
        //   resultset["dob"] = existingdata[i]["DOB"];
        // }

        resultset["dob"] = "";
        resultset["className"] = existingdata[i]["Class"];
        resultset["section"] = existingdata[i]["Section"];
        resultset["examTheme"] = existingdata[i]["ExamTheme"];
        resultset["demoExam"] = existingdata[i]["DemoExam"];
        resultset["schoolId"] = state.school_code;

        resultset["DemoSlotDateTime"] = existingdata[i]["DemoSlotDateTime"];
        resultset["ExamSlotDateTime"] = existingdata[i]["ExamSlotDateTime"];
        resultset["studentId"] = existingdata[i]["StudentID"] || null;
        resultset["studentSeqNo"] = existingdata[i]["StudentSeqNo"] || "";
        resultset["language"] = existingdata[i]["Language"] || "ENGLISH";
        if (
          // resultset["dob"].match(regex) === null ||
          classesdropdown.indexOf(parseInt(resultset["className"])) === -1 ||
          examThemedropdown.indexOf(resultset["examTheme"]) === -1 ||
          ["YES", "NO"].indexOf(resultset["demoExam"]) === -1
        ) {
          invalidDate = true;
        }
        resultset["error"] = invalidDate ? "invalid" : "valid";
        console.log("resultset", resultset);

        finalArr.push(resultset);
      }
      finalArr = finalArr.sort((a, b) =>
        parseInt(a.studentSeqNo) > parseInt(b.studentSeqNo) ? 1 : -1
      );
      // setIdCount(prevCount => prevCount + idVal);
      setIdCount(idVal);
      console.log("appStatus", appStatus);
      setStudanntData([...finalArr]);
    }
  };

  useEffect(() => {
    // checkStudentCount();
    getAppStatus();
  }, []);

  const submitStudantData = async (e) => {
    e.preventDefault();
    let serverData = [...studantData];
    if (serverData.length === 0) {
      alert("kindly upload some records to save ");
      return;
    }
    // let dupes = findDuplicates();
    // if (dupes) {
    //   return;
    // }

    // let res = await uploadFile(JSON.stringify(serverData));
    let studentuploaddone = await uploadFile();
    console.log("studentuploaddone", studentuploaddone);
  };

  const downloadexcel = async () => {
    document.getElementsByClassName("modal")[0].style.display = "block";
    const otp = await axios.get(
      `${API_BASE_JAVA_URL}${API_END_POINTS.downloadExcelTemplate}`,
      {
        headers: {
          "Content-Disposition": "attachment; filename=template.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      }
    );
    console.log("otp", otp);
  };

  const checkEmptyValueStudent = (student) => {
    if (
      student["name"] === "" ||
      student["studentSeqNo"] === "" ||
      // student["dob"] === "" ||
      student["className"] === "" ||
      student["section"] === "" ||
      student["examTheme"] === "" ||
      student["demoExam"] === "" ||
      student["language"] === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkDuplicateSerialNo = (students, student) => {
    const isDuplicate =
      students.filter(
        (obj) => parseInt(obj.studentSeqNo) === parseInt(student.studentSeqNo)
      ).length > 1;
    return isDuplicate;
  };

  const uploadFile = async () => {
    // let SCHOOLID = decodedSchoolData?.schoolsCode
    // return await axios.post(`${API_BASE_URL}${UPLOAD_ENDPOINT}/${SCHOOLID}`, { fileData: file });
    // return await axios.post(`${UPLOAD_ENDPOINT}/${SCHOOLID}`, { fileData: file });
    console.log("student data", studantData);
    let finalData = [];
    let studantDataVal = [...studantData];
    let errRows = [];
    let invalidDate = false;
    let isSubmit = true;
    //  check the date validation

    for (let i = 0; i < studantDataVal.length; i++) {
      // const regex = /^\d{2}-\d{2}-\d{4}$/;
      let isDuplicate = checkDuplicateSerialNo(
        studantDataVal,
        studantDataVal[i]
      );
      let emptyFields = checkEmptyValueStudent(studantDataVal[i]);
      // studantDataVal[i]["name"];
      studantDataVal[i]["name"] = studantDataVal[i]["name"].trim();
      studantDataVal[i]["demoExam"] = studantDataVal[i]["demoExam"].trim();
      studantDataVal[i]["language"] = studantDataVal[i]["language"].trim();
      studantDataVal[i]["section"] = studantDataVal[i]["section"]
        .toString()
        .trim();
      studantDataVal[i]["language"] =
        studantDataVal[i]["language"].toUpperCase();
      studantDataVal[i]["demoExam"] =
        studantDataVal[i]["demoExam"].toUpperCase();
      if (emptyFields || isDuplicate) {
        studantDataVal[i]["error"] = "invalid";
        setStudanntData([...studantDataVal]);
        notify(
          `Please correct the respective  columns of row number ${i + 1}`,
          false
        );
        return;
      }
      if (
        // studantDataVal[i]["dob"].match(regex) === null ||
        classesdropdown.indexOf(parseInt(studantDataVal[i]["className"])) ===
          -1 ||
        // examThemedropdown.indexOf(studantDataVal[i]["examTheme"]) === -1 ||
        ["YES", "NO"].indexOf(studantDataVal[i]["demoExam"]) === -1 ||
        ["ENGLISH", "HINDI"].indexOf(studantDataVal[i]["language"]) === -1
      ) {
        invalidDate = true;
        isSubmit = false;
        studantDataVal[i]["error"] = "invalid";
        errRows.push(i + 1);
      } else {
        studantDataVal[i]["error"] = "valid";
      }
      // studantDataVal[i]["StudentSeqNo"] = "";
    }

    setStudanntData([...studantDataVal]);
    if (isSubmit === false) {
      notify(
        `Please correct the respective  columns of row number ${errRows}`,
        false
      );
      return;
    }
    // else {
    finalData = [...studantData];
    for (let i = 0; i < finalData.length; i++) {
      delete finalData[i]["id"];
      delete finalData[i]["error"];
    }

    console.log("finalData", finalData);
    let fileupload = await axios.post(
      `${API_BASE_JAVA_URL}${API_END_POINTS.uploadApi}`,
      finalData
    );
    console.log("fileupload", fileupload);
    navigate("/school-slot");
    // checkStudentCount();
    // alert()
    // notify(`Students successfully uploaded!.`, true);
    // }
  };
  // Name  DOB  Class   Section ExamTheme MockTest

  const handleOnChange = (e) => {
    // console.log(e.target.files[0],'file name');
    let correctData = [];
    const f = e.target.files[0];
    if (f) {
      setFilename(f?.name);
      var r = new FileReader();
      r.onload = (e) => {
        var contents = processExcel(e.target.result);
        try {
          let d = JSON.parse(contents)?.Sheet1;
          let arr = [];
          for (let i = 0; i < d.length; i++) {
            if (d[i].length === 0) {
              continue;
            } else {
              arr.push(d[i]);
            }
          }
          setHeaders(arr.shift());
          console.log("arr", arr);
          // correctData = arr.map((exData, i) => [...exData.slice(0, 1), dayjs(new Date(exData.slice(1, 2))).format('DD-MM-YYYY'), ...exData.slice(2, 15)]);
          // correctData = arr.map((exData, i) => [
          //   ...exData.slice(0, 1),
          //   dayjs(ExcelDateToJSDate(exData[1])).format("DD-MM-YYYY"),
          //   ...exData.slice(2, 15),
          // ]);

          // correctData = arr.map((exData, i) => [
          //   ...exData.slice(0, 1),
          //   ...exData.slice(2, 15),
          // ]);

          correctData = [...arr];
          console.log("correctData", correctData);

          // if (correctData.length <= MINIMUMROW) {
          //   notify(`this file must contain minimum ${MINIMUMROW} rows.`, false)
          //   return
          // }
          // let duplicateRows = checkRowDuplicacy(correctData);
          // // console.log("=tetst",duplicateRows)
          // // setDuplicateRows(duplicateRows)
          // if (duplicateRows.length > 0) {
          //   notify(`${duplicateRows.join()}`, false)
          // }
          let finalArr = [];
          let idVal = JSON.parse(JSON.stringify(idCount));
          finalArr = [...studantData];
          for (let i = 0; i < correctData.length; i++) {
            let resultset = {};
            resultset["id"] = idVal++;
            resultset["name"] = correctData[i][1].trim();
            // resultset["dob"] = correctData[i][1];
            resultset["dob"] = "";
            resultset["studentSeqNo"] = "" + correctData[i][0];
            resultset["className"] = correctData[i][2];
            resultset["section"] = correctData[i][3];
            resultset["examTheme"] = "ESD";
            resultset["demoExam"] = correctData[i][4];
            resultset["language"] = correctData[i][5];
            resultset["schoolId"] = state.school_code;
            resultset["error"] = "valid";
            resultset["studentId"] = null;
            resultset["DemoSlotDateTime"] = null;
            resultset["ExamSlotDateTime"] = null;
            finalArr.push(resultset);
          }
          setIdCount(idVal);
          // setIdCount(prevCount => prevCount + idVal);
          console.log("idCount", idCount);
          setStudanntData([...finalArr]);
          // let dupesArr = checkDuplicateSerialNo([...finalArr]);
          // console.log("dupesArr", dupesArr);
        } catch (e) {
          notify(`file could not uploaded, please try again!.`, false);
        }
      };
      r.readAsBinaryString(f);
      e.target.value = null;
    } else {
      notify("Failed to load file!", false);
    }
    setFile(e.target.files[0]);

    console.log("studantData", studantData);
  };
  const deleteRow = async (data, i, studentId) => {
    console.log("deleteRow", data);

    if (studentId === null) {
      setStudanntData([
        ...studantData.filter((d, index) => {
          return index !== i;
        }),
      ]);
    } else {
      await axios.post(`${API_BASE_URL}${API_END_POINTS.deleteStudent}`, {
        studentId: studentId,
      });
      setStudanntData([
        ...studantData.filter((d, index) => {
          return index !== i;
        }),
      ]);
    }
  };
  const editRow = (data, i) => {
    let inDom = document.querySelectorAll(`._tbls${i}`);
    inDom.forEach(function (node) {
      node.removeAttribute("disabled");
      node.classList.add("edit-cell");
      // Do whatever you want with the node object.
    });
  };

  const movetonext = () => {
    document.getElementsByClassName("modal")[0].style.display = "none";
  };

  const handleOnChangeCell = (e, columnName, i) => {
    let cellValue = e.target.value;
    let perData = [...studantData];
    let updatedate = perData.map((value, index) => {
      if (index === i) {
        value[columnName] = cellValue;
        return value;
      } else {
        return value;
      }
    });

    // let cellValue = e.target.value
    // let perData = [...studantData];
    // let updatedate = perData.map((sd, ii) => {
    //   if (ii == i) {
    //     sd[+cell] = cellValue
    //     return sd;
    //   } else { return sd }
    // })

    setStudanntData(updatedate);
  };

  const findDuplicates = () => {
    let tempArr = [...studantData];

    let arr = Object.values(
      tempArr.reduce((c, v) => {
        // let k = v.name + "-" + v.dob + "-" + v.examTheme;
        let k = v.name + "-" + v.examTheme;
        c[k] = c[k] || [];
        c[k].push(v);
        return c;
      }, {})
    ).reduce((c, v) => (v.length > 1 ? c.concat(v) : c), []);

    console.log("arr", arr);

    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]["studentId"] === null) {
        str += arr[i]["id"];
        str += " ";
      }
    }

    console.log("str", str);
    if (str === "") {
      return false;
    } else {
      setDupErr(`Row number ${str} are duplicate records kindly remove them.`);
      return true;
    }
    // if (str === "" ? false : true);
  };

  const addNewRow = (e) => {
    console.log(stName && stDOB && stClass && stSection && demoExam);
    e.preventDefault();
    // if (!(stName && stDOB && stClass && stSection && examTheme && demoExam)) {
    if (
      !(stName && stClass && stSection && demoExam && language && serialNumber)
    ) {
      return;
    }
    console.log("idCount", idCount);

    let runningId = JSON.parse(JSON.stringify(idCount));
    let exceldataset = [...studantData];
    let resultset = {};
    resultset["id"] = runningId++;
    resultset["name"] = stName.trim();
    // resultset["dob"] = stDOB;
    resultset["dob"] = "";
    resultset["className"] = stClass;
    resultset["section"] = stSection;
    resultset["examTheme"] = "ESD";
    resultset["demoExam"] = demoExam;
    resultset["schoolId"] = state.school_code;
    resultset["error"] = "valid";
    resultset["DemoSlotDateTime"] = null;
    resultset["ExamSlotDateTime"] = null;
    resultset["studentId"] = null;
    resultset["language"] = language;
    resultset["studentSeqNo"] = "" + serialNumber;
    const regex = /^\d{2}-\d{2}-\d{4}$/;
    let invalidDate = false;
    let errRows = [];
    if (
      // resultset["dob"].match(regex) === null ||
      classesdropdown.indexOf(parseInt(resultset["className"])) === -1 ||
      examThemedropdown.indexOf(resultset["examTheme"]) === -1 ||
      ["YES", "NO"].indexOf(resultset["demoExam"]) === -1
    ) {
      resultset["error"] = "invalid";
      errRows.push(exceldataset.length + 1);
    }

    exceldataset.push(resultset);

    // findDuplicates(exceldataset);
    setStudanntData([...exceldataset]);
    setIdCount(runningId);

    setStName("");
    // setDOB("");
    setDatePopulate("");
    setClass("4");
    setexamTheme("ESD");
    setSection("");
    setDemoExam("NO");
    setLanguage("ENGLISH");
    setSerialNumber("");
  };
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-3">
          <Sidebar mode={state?.mode} />
        </div>
        <div className="col-lg-9 ">
          <main className="content ">
            <div className="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
              <div className="section-title mb-4 text-muted">
                <h6 className="font-bold ">Upload Students Information</h6>
                <p>
                  Schools have an option to upload students information by
                  downloadable excel sheet OR through a form
                </p>
              </div>

              <div className="shadow bg-white mb-5 rounded-16">
                <div className="p-4">
                  <div className="row">
                    <div className="col-sm-7 mb-5 mb-sm-0">
                      <div className="upload-box text-center h-100">
                        <label>
                          {/* <input type="file" name="upload" accept=".xlsx" /> */}
                          <input
                            className="upload"
                            type="file"
                            placeholder="Name"
                            name="uname"
                            required
                            onChange={handleOnChange}
                          />
                          <img src={uploadfiles} alt="" />
                          <br />
                          <strong>Choose excel</strong>
                          <h5>{filename}</h5>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-5">
                      <div className="d-flex h-100 flex-column justify-content-around">
                        <ExportCSV />
                        {/* <button className="btn btn-primary mb-4 mb-sm-0 w-100" onClick={downloadexcel}>Download Excel Format</button> */}
                        {/* <button className="btn btn-primary w-100 ">Upload Student Data</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section-title mb-4 text-muted">
                <div className="d-flex">
                  {/* <div className="me-auto" style={{ visibility: "hidden" }}>
                    <h6 className="font-bold ">Add Student Data</h6>
                    <p>Add Student Data from Add button</p>
                  </div> */}
                  <button
                    className="btn btn-outline-secondary createRowBtn"
                    data-bs-toggle="collapse"
                    data-bs-target="#newStudentRow"
                  >
                    <svg className="icon align-middle me-1">
                      <use xlinkHref="#add-plus"></use>
                    </svg>{" "}
                    <span className="align-middle">Create Row</span>
                  </button>
                </div>
              </div>

              <div id="newStudentRow" className="collapse">
                <div className="table-responsive ">
                  <table className="table table-bordered table-accent">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>S.NO.</th>
                        <th>NAME</th>
                        {/* <th>DOB</th> */}
                        <th>CLASS</th>
                        <th>SECTION</th>
                        {/* <th>EXAM THEME</th> */}
                        <th>Sample Question Booklet</th>
                        <th>Medium of Examination</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="w-auto">
                          <button className="btn btn-sm btn-outline-secondary d-flex">
                            <svg className="icon align-middle me-1">
                              <use xlinkHref="#add-plus"></use>
                            </svg>{" "}
                            <span
                              className="align-middle w-100"
                              onClick={addNewRow}
                            >
                              Insert Row
                            </span>
                          </button>
                        </td>
                        <td className="w-auto">
                          <div className="form-wrapper">
                            {/* <input type="text" placeholder="Enter name" style={{ width: '220px' }} /> */}
                            <input
                              type="text"
                              name="sNo"
                              style={{
                                width: "59px",
                                padding: "6px 15px",
                                margin: "0px",
                                display: "inline-block",
                                border: "1px solid #ccc",
                                boxSizing: "border-box",
                                borderRadius: "14px",
                              }}
                              value={serialNumber}
                              onChange={(e) => setSerialNumber(e.target.value)}
                            />
                          </div>
                        </td>

                        <td>
                          <div className="form-wrapper">
                            {/* <input type="text" placeholder="Enter name" style={{ width: '220px' }} /> */}
                            <input
                              type="text"
                              name="add1"
                              style={{
                                width: "184px",
                                padding: "6px 15px",
                                margin: "0px",
                                display: "inline-block",
                                border: "1px solid #ccc",
                                boxSizing: "border-box",
                                borderRadius: "14px",
                              }}
                              value={stName}
                              onChange={(e) => setStName(e.target.value)}
                            />
                          </div>
                        </td>
                        {/* <td>
                          <div className="form-wrapper">
                          
                            <input
                              type="date"
                              placeholder="choose date"
                              value={datePopulate}
                              name="dob"
                              onChange={(e) => {
                                let newdate = dayjs(
                                  new Date(e.target.value)
                                ).format("DD-MM-YYYY");
                                setDOB(newdate);
                                setDatePopulate(e.target.value);
                              }}
                              required=""
                            />
                           
                          </div>
                        </td> */}
                        <td>
                          <div className="form-wrapper">
                            {/* <input type="text" name="add1"
                              style={{ width: '60px' }}

                              onChange={e => setClass(e.target.value)}
                              value={stClass}


                            /> */}

                            <select
                              name="class"
                              value={stClass}
                              onChange={(e) => setClass(e.target.value)}
                            >
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="form-wrapper">
                            {/* <input type="text" placeholder="A" style={{ width: '60px' }} /> */}
                            <input
                              type="text"
                              name="add1"
                              style={{ width: "60px" }}
                              onChange={(e) => setSection(e.target.value)}
                              value={stSection}
                            />
                          </div>
                        </td>
                        {/* <td>
                          <div className="form-wrapper">
                           

                            <select
                              name="class"
                              value={examTheme}
                              onChange={(e) => setexamTheme(e.target.value)}
                            >
                              <option value="ESD">ESD</option>
                             
                            </select>
                          </div>
                        </td> */}
                        <td>
                          <div className="form-wrapper">
                            <select
                              name="class"
                              value={demoExam}
                              onChange={(e) => setDemoExam(e.target.value)}
                            >
                              <option value="YES">YES</option>
                              <option value="NO">NO</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="form-wrapper">
                            <select
                              name="class"
                              value={language}
                              onChange={(e) => setLanguage(e.target.value)}
                            >
                              <option value="ENGLISH">ENGLISH</option>
                              <option value="HINDI">HINDI</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="section-title mb-4 text-muted">
                <div className="d-flex">
                  <div className="me-auto">
                    <h6 className="font-bold ">Add Student Data</h6>
                    <p>Add Student Data from Add button</p>
                  </div>
                  <button className="btn btn-outline-secondary"><svg className="icon align-middle me-1">
                    <use xlinkHref="#add-plus"></use>
                  </svg> <span className="align-middle" onClick={addNewRow}>Insert Row</span></button>
                </div>
              </div> */}

              <div className="shadow bg-white p-3 rounded-16">
                <div className="table-responsive ">
                  <table className="table table-bordered table-accent">
                    <thead>
                      <tr>
                        <th>ACTION</th>
                        <th style={{ width: "30px" }}>S.NO.</th>
                        <th>NAME</th>
                        {/* <th>DOB</th> */}
                        <th>CLASS</th>
                        <th>SECTION</th>
                        {/* <th>EXAM THEME</th> */}
                        <th style={{ width: "10em" }}>
                          Sample Question Booklet
                        </th>
                        <th>Medium of Examination</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                    <tbody>
                      {studantData.map((tbData, i) => {
                        // console.log("tbData", tbData);
                        return (
                          <tr
                            key={tbData.id}
                            className={
                              tbData["error"] === "invalid"
                                ? "invalid"
                                : "valid"
                            }
                          >
                            <td
                              style={{
                                display: "flew",
                                flexDirection: "row",
                              }}
                            >
                              {/* student id not null  =>. not first time*/}
                              {/* ExamSlotDateTime not null already inserted */}
                              {/* DemoSlotDateTime not null already inserted */}
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-link"
                                  disabled={
                                    tbData["studentId"] !== null &&
                                    tbData["ExamSlotDateTime"] !== null
                                      ? true
                                      : false
                                  }
                                  onClick={(e) => editRow(tbData, i)}
                                >
                                  <svg className="icon">
                                    <use xlinkHref="#edit"></use>
                                  </svg>
                                </button>
                                {/* <div className="vr"></div> */}
                                <div
                                  className={`vr ${
                                    tbData["studentId"] !== null
                                      ? "disableRowBtn"
                                      : ""
                                  }`}
                                ></div>
                                <button
                                  type="button"
                                  className={`btn btn-link ${
                                    tbData["ExamSlotDateTime"] !== null
                                      ? "disableRowBtn"
                                      : ""
                                  } `}
                                  disabled={
                                    tbData["studentId"] !== null &&
                                    tbData["ExamSlotDateTime"] !== null
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    deleteRow(tbData, i, tbData["studentId"])
                                  }
                                >
                                  <svg className="icon">
                                    <use xlinkHref="#delete"></use>
                                  </svg>
                                </button>
                              </div>
                            </td>
                            {/* <td>{tbData.id}</td> */}
                            <td>
                              <input
                                type="text"
                                name="sNo"
                                defaultValue={tbData["studentSeqNo"] ?? ""}
                                style={{
                                  margin: "0px",
                                  width: "91px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  boxSizing: "border-box",
                                  borderRadius: "14px",
                                }}
                                className={`_tbls${i} w-100 py-2`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "studentSeqNo", i)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="add1"
                                defaultValue={tbData["name"] ?? ""}
                                style={{
                                  width: "173px",
                                  padding: "6px 15px",
                                  margin: "0px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  boxSizing: "border-box",
                                  borderRadius: "14px",
                                }}
                                className={`_tbls${i}`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "name", i)
                                }
                              />
                            </td>
                            {/* <td>
                              <input
                                type="text"
                                name="add1"
                                defaultValue={tbData["dob"] ?? ""}
                                style={{
                                  width: "129px",
                                  padding: "6px 15px",
                                  margin: "0px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  "box-sizing": "border-box",
                                  "border-radius": "14px",
                                }}
                                className={`_tbls${i}`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "dob", i)
                                }
                              />
                            </td> */}
                            <td>
                              <input
                                type="text"
                                name="add1"
                                defaultValue={tbData["className"] ?? ""}
                                style={{
                                  width: "55px",
                                  padding: "6px 15px",
                                  margin: "0px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  boxSizing: "border-box",
                                  borderRadius: "14px",
                                }}
                                className={`_tbls${i}`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "className", i)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="add1"
                                defaultValue={tbData["section"] ?? ""}
                                style={{
                                  width: "55px",
                                  padding: "6px 15px",
                                  margin: "0px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  boxSizing: "border-box",
                                  borderRadius: "14px",
                                }}
                                className={`_tbls${i}`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "section", i)
                                }
                              />
                            </td>
                            {/* <td>
                              <input
                                type="text"
                                name="add1"
                                defaultValue={tbData["examTheme"] ?? ""}
                                style={{
                                  // width: "120px",
                                  // padding: "6px 15px",
                                  margin: "0px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  "box-sizing": "border-box",
                                  "border-radius": "14px",
                                }}
                                className={`_tbls${i} w-50 py-2`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "examTheme", i)
                                }
                              />
                            </td> */}
                            <td>
                              <input
                                type="text"
                                name="add1"
                                defaultValue={tbData["demoExam"] ?? ""}
                                style={{
                                  width: "63px",
                                  padding: "6px 15px",
                                  margin: "0px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  boxSizing: "border-box",
                                  borderRadius: "14px",
                                }}
                                className={`_tbls${i}`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "demoExam", i)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="add1"
                                defaultValue={tbData["language"] ?? ""}
                                style={{
                                  width: "63px",
                                  padding: "6px 15px",
                                  margin: "0px",
                                  display: "inline-block",
                                  border: "1px solid rgb(255 243 243)",
                                  boxSizing: "border-box",
                                  borderRadius: "14px",
                                }}
                                className={`_tbls${i} w-100`}
                                disabled
                                onChange={(e) =>
                                  handleOnChangeCell(e, "language", i)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div>
                  <h2 className="fs-3"> Points to keep in mind</h2>
                  <h3>
                    <ul>
                      <li>
                        S.No. should be numeral & value should not be repeated.
                      </li>
                      <li>
                        The name of the students should be in capital letters.
                      </li>
                      <li>Class should be in numeral format from 4 - 12.</li>
                      <li>
                        Response for Sample Paper is either a YES or a NO (in
                        capital letters)
                      </li>
                      <li>
                        {" "}
                        Response for Medium of Examination is either a ENGLISH
                        or a HINDI (in capital letters)
                      </li>
                      <li>
                        Through your school code, system will allow you to add
                        more names for registration in GREEN Olympiad 2023
                      </li>
                      <li>Kindly check students’ details before submitting.</li>
                    </ul>
                  </h3>
                </div>
                <div className="row my-3">
                  <button
                    className="btn btn-primary mx-auto"
                    style={{ width: "15rem" }}
                    onClick={submitStudantData}
                  >
                    Save Student Data
                  </button>
                </div>
                <h2>{dupErr}</h2>
                {/* <div className="modal" id="myModalexam">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <h2>Excel format is downloaded successfully.</h2>
                      <h2>Kindly use this excel format to upload the student data.</h2>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary" onClick={movetonext}>Ok</button>
                    </div>
                  </div>
                </div> */}

                <div className="modal" id="myModalexam">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      {/* <div className="modal-header">
                        <h5 className="modal-title">Slots for Examination</h5>
                        <button type="button" className="btn-close" data-dismiss="modal">wqwqwq</button>
                      </div> */}

                      <div className="modal-body">
                        <div className="table-responsive ">
                          <h2>Excel format is downloaded successfully.</h2>
                          <h2>
                            Kindly use this excel format to upload the student
                            data.
                          </h2>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={movetonext}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
