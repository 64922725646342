import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Error from "../school/ErrorList";
import { useNavigate } from "react-router";
import {
  API_ADMIN_URL_2,
  REGISTER_API,
  API_BASE_URL,
  API_END_POINTS,
  API_BASE_JAVA_URL,
} from "../../apis/api";
import { StudentDataContext } from "../context/datacontext";
import { scrollToTop } from "../../helper/helper";
const dayjs = require("dayjs");

export default function StudentIndigoRegistration({ isLogged }) {
  const { dispatch } = useContext(StudentDataContext);
  const navigate = useNavigate();
  const [mobileOTP, setmobileOTP] = useState([-1, -1, -1, -1]);
  const [emailOTP, setemailOTP] = useState([-1, -1, -1, -1]);
  const ageValues = ["18", "19", "20", "21", "22", "23", "24", "25"];
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  // const [mobileOTPValue, setMobileOTPValue] = useState("");
  const [mobileOTPValue, setMobileOTPValue] = useState(["", "", "", ""]);
  const [emailOTPValue, setEmailOTPValue] = useState("");
  const [error_message, setError_message] = useState("");
  const [errorList, setErrorList] = useState(Error);
  const [countryList, setCountryList] = useState([]);
  const [dateOriginal, setDateOriginal] = useState("");
  const [roll_no, setRoll_no] = useState("");
  const [pin, SetPin] = useState("");
  const [country, setCountry] = useState("");
  const [date, setDate] = useState("");
  const [state, setStateVal] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [add1, SetAdd1] = useState("");
  const [stateCityName, setCityStateName] = useState("Select State/Province");
  const [isIndain, setIsIndain] = useState(true);
  const [cityStateList, setCityStateList] = useState([]);
  const [data, setData] = useState("");

  const [mobileverify, setmobileverify] = useState(0);
  const [mobileVerMsg, setMobileVerMsg] = useState("");

  const [collegeName, setCollegeName] = useState("");
  const [emilVerMsg, setEmailVerMsg] = useState("");

  const [isFade, setIsfade] = useState(true);

  const [msgText, setMsgText] = useState("");

  const generateOtp = async () => {
    if (mobile !== "") {
      const otp = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.generateOtp}`,
        { mobile: mobile }
      );
      if (otp?.data.status) {
        // setMobileOTPValue(otp.data.otp);
        setmobileOTP(otp.data.otp.split(""));
        setMsgText("OTP sent on your registered mobile number");
        document.getElementsByClassName("modal")[0].style.display = "block";
      } else {
        setMsgText(
          "Due to some reasons Your OTP culd not be send on your registered email id"
        );
        document.getElementsByClassName("modal")[0].style.display = "block";
        //  error in generating otp
      }
    }
  };

  const handleChange = (a, k) => {
    setData((prevvalue) => {
      return { ...prevvalue, [k]: a };
    });
  };

  const sortCountryList = (list) => {
    return list.sort(function (a, b) {
      if (a.country < b.country) {
        return -1;
      }
      if (a.country > b.country) {
        return 1;
      }
      return 0;
    });
  };

  const changeCityState = (event) => {
    setStateVal("");
    let countryData = JSON.parse(event.target.value);

    setCountry(event.target.value);
    if (countryData.countryCode !== "IN") {
      setIsIndain(false);
      setCityStateName("Select State/Province");
    } else {
      setIsIndain(true);
      setCityStateName("Select State/Province");
    }
    getCityState(countryData.countryCode);
  };

  const getCityState = async (countryCode) => {
    const endPoint =
      countryCode === "IN"
        ? API_END_POINTS.getIndianState
        : API_END_POINTS.getInternationalCities + `'${countryCode}'`;
    const cityStateList = await axios.get(`${API_BASE_URL}${endPoint}`);
    //const cityStateList = await axios.get(`${endPoint}`);
    if (cityStateList.status === 200 && cityStateList.data.status) {
      let list = sortStateList(cityStateList.data.list);
      setCityStateList(list);
      // handleChange('', 'state');
    } else {
      setCityStateList([]);
    }
  };

  const closeModal = () => {
    document.getElementsByClassName("modal")[0].style.display = "none";
  };

  const semdEmail = async () => {
    setIsfade(false);
    if (email !== "") {
      let regExp = RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
      if (regExp.test(email)) {
        const emailvalue = await axios.get(
          `${API_BASE_URL}${API_END_POINTS.sendEmailToCandidate}`,
          {
            //let response = await axios.get(`${API_BASE_JAVA_URL}${API_END_POINTS.getslots}`, {
            params: {
              email: `${email}`,
              email_header: "New User",
            },
          }
        );
        console.log("emailvalue", emailvalue);
        if (emailvalue?.data?.status) {
          setEmailOTPValue(emailvalue.data.otp);
          setMsgText("OTP has been sent on your registered email id");
          document.getElementsByClassName("modal")[0].style.display = "block";
        } else {
          setMsgText(
            "Due to either non validity of email id email could not be send on your registered email id"
          );
          document.getElementsByClassName("modal")[0].style.display = "block";
          setEmailOTPValue("");
        }
      }
    }

    // console.log("emailvalue", emailvalue);
  };

  const handleOtpChange = (event, index) => {
    const value = event.target.value;
    const newOTP = [...mobileOTPValue];
    newOTP[index] = value;
    setMobileOTPValue(newOTP);

    if (value === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const movetonext = () => {
    isLogged(true);
    navigate("/student-Inedit-details");
    document.getElementsByClassName("modal")[0].style.display = "none";
  };

  const handleCloseModal = () => {
    setIsfade(true);
  };

  const sortStateList = (list) => {
    return list.sort(function (a, b) {
      if (a.statename < b.statename) {
        return -1;
      }
      if (a.statename > b.statename) {
        return 1;
      }
      return 0;
    });
  };

  const submitvalue = async () => {
    let err = checkAllField();

    if (err) return err;

    if (isIndain) {
      if (mobileverify === 0) {
        setError_message("Please validate mobile otp");
        return;
      }
    }

    // if (emailverify === 0) {
    //   setError_message('Please validate email otp');
    //   return;
    // }

    console.log(country, state, name, mobile, date, email);

    let countryval = JSON.parse(country);
    let stateVal = JSON.parse(state);
    let statecode =
      stateVal.stateCode.length === 1
        ? `0${stateVal.stateCode}`
        : stateVal.stateCode;
    let rollNoPrefix = `${countryval.countryCode}24${statecode}`;
    let obj = {
      add1,
      add2: "",
      city: "",
      // country: countryval.countryname,
      // countryCode: countryval.countryCode,
      country: "India",
      countryCode: "IN",
      createdBy: "",
      demoExam: "No",
      dob: date,
      email: email,
      examLevel: "",
      examTheme: "GO4U",
      gender: "",
      mobile: mobile,
      modifiedby: "",
      name: name,
      password: mobile,
      pgEmail: "",
      pgMobile: "",
      pin,
      rollNoPrefix: rollNoPrefix,
      school: collegeName,
      section: "",
      standard: "",
      state: stateVal.state,
      state_city_cd: stateVal.stateCode,
      indigo: "go4youth",
    };

    const reg_res = await axios.post(
      `${API_BASE_JAVA_URL}${API_END_POINTS.registerStudent}`,
      obj
    );
    if (reg_res?.status && reg_res?.data !== "") {
      console.log(reg_res?.data);
      setRoll_no(reg_res?.data);
      dispatch({
        type: "ADD_ROLL_NO",
        roll_no: reg_res?.data,
      });
      document.getElementsByClassName("modal")[0].style.display = "block";
      const mail = await axios.post(
        `${API_BASE_URL}${API_END_POINTS.sendEmail}`,
        {
          roll_no: reg_res?.data,
          pass: mobile,
          textheader: "ROLL NO :",
          email,
        }
      );
      await axios.post(
        `${API_BASE_URL}${API_END_POINTS.sendconfirmationToStudent}`,
        {
          login_id: reg_res?.data,
          password: mobile,
          mobile: mobile,
        }
      );
      if (mail?.status) {
        console.log("mail");
      }

      // navigate("/student-edit-details");
      // setMobileOTPValue(otp.data.otp);
    }
  };

  const mobileOTPset = (ev, index) => {
    mobileOTP[index] = ev.target.value;

    setmobileOTP(mobileOTP);
  };

  const emailOTPset = (ev, index) => {
    emailOTP[index] = ev.target.value;

    setemailOTP(emailOTP);
  };

  const getCountry = async () => {
    const countryList = await axios.get(
      `${API_BASE_URL}${API_END_POINTS.getCountry}`
    );
    //const countryList = await axios.get(`${API_END_POINTS.getCountry}`);
    try {
      if (countryList?.status === 200 && countryList?.data?.status) {
        let list = sortCountryList(countryList.data.list);
        setCountryList(list);
        let obj = JSON.stringify({ countryCode: "IN", countryname: "India" });
        setCountry(obj);
        setIsIndain(true);
        getCityState("IN");
        setCityStateName("Select State/Province");
      } else {
        setCountryList([]);
      }
    } catch (e) {
      console.log("error");
    }
  };

  useEffect(() => {
    getCountry();
    scrollToTop();
  }, []);

  const checkAllField = () => {
    let arr = [
      // country,
      state,
      name,
      date,
      mobile,
      email,
      collegeName,
      add1,
      pin,
    ];
    let arrKey = [
      // "country",
      "state",
      "name",
      "date",
      "mobile",
      "email",
      "collegeName",
      "add1",
      "pin",
    ];
    let err = "";
    arr.forEach((value, index) => {
      if (err === "") {
        err = formValidate({ key: arrKey[index], value: value });
      }
    });
    return err;
  };

  const otpMobileverifcation = () => {
    // document.getElementsByClassName('modal')[0].style.display = 'block';
    // if (mobileOTP.join("") === mobileOTPValue) {
    if (parseInt(mobileOTP.join("")) === parseInt(mobileOTPValue.join(""))) {
      // if (mobileOTP.join('') === '4444') {
      setMobileVerMsg("Your mobile has been verified");
      setmobileverify(1);
    } else {
      setMobileVerMsg("Your mobile has not been verified");
      setmobileverify(0);
    }
  };

  const goToHome = () => {
    navigate("/");
  };

  // const otpEmailVerifcation = () => {
  //   if (emailOTP.join("") === emailOTPValue) {
  //     setEmailVerMsg("Your email has been verified");
  //     setemailverify(1);
  //   } else {
  //     setEmailVerMsg("Your email has not been verified");
  //     setemailverify(0);
  //   }
  // };

  const formValidate = (e) => {
    const { key, value } = e;
    let err = "";
    setError_message("");
    // mobileverify
    // emailverify

    switch (key) {
      case "country":
      case "state":
        if (value.length < 1)
          err = errorList.find((item) => item.fieldNam === key).message;
        break;
      case "date":
        if (!value) {
          err = errorList.find((item) => item.fieldNam === key).message;
        }
        //  else if (!dayjs(dateOriginal).isBefore(dayjs())) {
        //   err = errorList.find((item) => item.fieldNam === key).message2;
        // }

        break;
      case "collegeName":
        if (!value) {
          err = errorList.find((item) => item.fieldNam === key).message;
        }

        break;
      case "name":
        if (value.length < 1)
          err = errorList.find((item) => item.fieldNam === key).message;
        break;

      case "mobile":
        // if (value.length < 1 && isIndain)
        //   err = (errorList.find(item => item.fieldNam === key).message);
        // if (err === "") {
        //   let item = errorList.find(item => item.fieldNam === key);
        //   let regExp = RegExp(item.regex)
        //   err = (regExp.test(value) ? "" : item.message2);
        // }

        // if (isIndain) {
        if (value.length < 1)
          err = errorList.find((item) => item.fieldNam === key).message;
        if (err === "" && isIndain) {
          let item = errorList.find((item) => item.fieldNam === key);
          let regExp = RegExp(item.regex);
          err = regExp.test(value) ? "" : item.message2;
        }
        // }
        break;
      case "add1":
        if (!value)
          err = errorList.find((item) => item.fieldNam === key).message;
        break;
      case "pin":
        if (value.length === 0 && isIndain) {
          err = errorList.find((item) => item.fieldNam === key).message;
        }
        if (err === "" && isIndain) {
          if ("" + value === "000000" || value.length !== 6) {
            err = errorList.find((item) => item.fieldNam === key).message;
          }
        }
        console.log("err", err);
        break;
      case "email":
        if (value.length === 0)
          err = errorList.find((item) => item.fieldNam === key).message;
        if (err === "") {
          let item = errorList.find((item) => item.fieldNam === key);
          let regExp = RegExp(item.regex);
          err = regExp.test(value) ? "" : item.message2;
        }
        break;
      default:
        break;
    }
    setError_message(err);
    return err;
  };

  return (
    <div class="container-fluid ">
      <div class="row ">
        <div class="col-lg-10 mx-auto">
          <main class="p-3 p-sm-4 p-lg-5">
            <div class="section-title mb-4 text-muted">
              <h6 class="font-bold">New Registration</h6>
              {/* <p>Fill this form for registration</p> */}
            </div>

            <div class="shadow bg-light rounded-16">
              <div class="p-4 ">
                {/* <div class="row">
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>
                        Country<span style={{ color: "red" }}>*</span>
                      </label>

                      <select
                        value={country}
                        required
                        onChange={changeCityState}
                      >
                        <option value="volvo" id="country_id">
                          Select Country
                        </option>
                        {countryList.map((co) => {
                          return (
                            <option
                              value={JSON.stringify({
                                countryCode: co?.code,
                                countryname: co?.country,
                              })}
                              key={co.code}
                            >
                              {co?.country}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div> */}
                <div class="row">
                  <div class=" col-sm">
                    <div class="form-wrapper">
                      <label>
                        Name of the student
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        name="name"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>
                        State/Province<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="dropdown"
                        id="cars"
                        onChange={(e) => {
                          setStateVal(e.target.value);
                        }}
                        value={state || ""}
                      >
                        <option value="volvo">Select State/Province</option>

                        {cityStateList &&
                          cityStateList.map((ci) => {
                            return (
                              <option
                                key={ci?.cityname || ci?.statename}
                                value={JSON.stringify({
                                  stateCode: ci?.srn || ci?.citycode,
                                  state: ci?.cityname || ci?.statename,
                                })}
                              >
                                {ci.cityname || ci?.statename}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class=" col-sm">
                    <div class="form-wrapper">
                      <label>
                        Age (18- 25 years)
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <input
                        type="date"
                        placeholder=""
                        value={dateOriginal}
                        onChange={(e) => {
                          let dateval = dayjs(e.target.value).format(
                            "DD-MM-YYYY"
                          );
                          setDate(dateval);
                          setDateOriginal(e.target.value);
                        }}
                        name="dob"
                        required=""
                      /> */}
                      <select
                        className="dropdown"
                        id="age"
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                        value={date || ""}
                      >
                        <option value="volvo">Select Age</option>

                        {ageValues &&
                          ageValues.map((age, index) => {
                            return (
                              <option key={index} value={age}>
                                {age}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>
                        Name of the Institution
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div class="d-flex">
                        <input
                          type="text"
                          class="me-3"
                          placeholder=""
                          onChange={(e) => {
                            setCollegeName(e.target.value);
                            formValidate({
                              key: "collegeName",
                              value: e.target.value,
                            });
                          }}
                          name="collegeName"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>
                        E-Mail id<span style={{ color: "red" }}>*</span>
                      </label>
                      <label className="font-bold">
                        (Kindly note this will be used for all communications)
                      </label>
                      <div class="d-flex">
                        <input
                          type="email"
                          class="me-3"
                          placeholder=""
                          value={email}
                          name="email"
                          onChange={(e) => {
                            setEmail(e.target.value.toLowerCase());
                            formValidate({
                              key: "email",
                              value: e.target.value.toLowerCase(),
                            });
                          }}
                          required=""
                        />
                        {/* <button class="otbutton btn btn-accent" style={{ whiteSpace: 'nowrap' }} onClick={semdEmail}>Generate OTP</button> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>
                        Address of the Institution
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        name="address"
                        placeholder=""
                        value={add1}
                        onChange={(e) => {
                          SetAdd1(e.target.value);
                          formValidate({ key: "add1", value: e.target.value });
                        }}
                        cols="30"
                        rows="1"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>
                        Mobile number<span style={{ color: "red" }}>*</span>
                      </label>
                      <div class="d-flex">
                        <input
                          type="text"
                          class="me-3"
                          placeholder=""
                          onChange={(e) => {
                            setMobile(e.target.value);
                            formValidate({
                              key: "mobile",
                              value: e.target.value,
                            });
                          }}
                          name="mobile"
                          required=""
                        />
                        {isIndain && (
                          <button
                            class="otbutton flex-grow-1 btn btn-accent"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={generateOtp}
                          >
                            Generate OTP
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {isIndain && (
                    <div class="col-sm">
                      <div class="form-wrapper">
                        <label>
                          Mobile OTP<span style={{ color: "red" }}>*</span>
                        </label>
                        <div class=" d-flex justify-content-between">
                          {mobileOTPValue.map((otp, index) => (
                            <input
                              key={index}
                              type="text"
                              className="me-3 pw1"
                              maxLength={1}
                              onChange={(ev) => {
                                handleOtpChange(ev, index);
                              }}
                              name="psw0"
                              placeholder-type="number"
                              ref={inputRefs[index]}
                            />
                          ))}

                          {/* <input
                            type="text"
                            class="me-3"
                            maxLength={1}
                            placeholder=""
                            onChange={(ev) => {
                              mobileOTPset(ev, 0);
                            }}
                            name="otp"
                            required=""
                          />
                          <input
                            type="text"
                            class="me-3"
                            maxLength={1}
                            placeholder=""
                            onChange={(ev) => {
                              mobileOTPset(ev, 1);
                            }}
                            name="otp"
                            required=""
                          />
                          <input
                            type="text"
                            class="me-3"
                            maxLength={1}
                            placeholder=""
                            onChange={(ev) => {
                              mobileOTPset(ev, 2);
                            }}
                            name="otp"
                            required=""
                          />
                          <input
                            type="text"
                            class="me-3"
                            maxLength={1}
                            placeholder=""
                            onChange={(ev) => {
                              mobileOTPset(ev, 3);
                            }}
                            name="otp"
                            required=""
                          /> */}
                          <button
                            class="otbutton btn btn-accent"
                            onClick={otpMobileverifcation}
                          >
                            Verify
                          </button>
                        </div>
                        <div>
                          <h5>{mobileVerMsg}</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div class="col-sm">
                    <div class="form-wrapper">
                      <label>
                        Pin Code<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        name="pincode"
                        value={pin}
                        onChange={(e) => {
                          SetPin(e.target.value);
                          formValidate({ key: "pin", value: e.target.value });
                        }}
                        required=""
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-4 mb-3">
                  <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                    <button
                      class="btn btn-primary mx-2  mb-4 mb-sm-0"
                      style={{ minWidth: "15rem" }}
                      onClick={submitvalue}
                    >
                      Save &amp; Proceed
                    </button>
                    <button
                      class="btn btn-primary mx-2 "
                      style={{ minWidth: "10rem" }}
                      onClick={goToHome}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <h3>
                      All fields marked with{" "}
                      <span style={{ color: "red" }}>*</span> are mandatory
                    </h3>
                  </div>
                </div>

                {error_message && (
                  <div className="alert alert-danger w-100" role="alert">
                    {error_message}
                  </div>
                )}
                {/* modal start */}

                <div className="modal" id="myModalexam">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      {/* <div className="modal-header">
                        <h5 className="modal-title">Slots for Examination</h5>
                        <button type="button" className="btn-close" data-dismiss="modal">wqwqwq</button>
                      </div> */}

                      {msgText.length > 0 && roll_no.length === 0 && (
                        <>
                          <div className="modal-body">
                            <div className="table-responsive ">
                              <h3>{msgText}</h3>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={closeModal}
                            >
                              Ok
                            </button>
                          </div>
                        </>
                      )}

                      {roll_no.length > 0 && (
                        <>
                          <div className="modal-body">
                            <div className="table-responsive ">
                              <div className="table-responsive ">
                                <h3>
                                  Login Id is {roll_no} and password is {mobile}
                                </h3>
                                <h4>
                                  Remember this code and password for logging in
                                  future.
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={movetonext}
                            >
                              Ok
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* modal end */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
