import axios from 'axios';
import React, { useState } from 'react'
import { API_BASE_URL, API_END_POINTS } from '../../apis/api';
import AdminSidebar from '../main/adminSidebar'

function GenerateRoll({ isAdmin }) {

    const [schoolcode, setSchoolCode] = useState('');
    const [err, setErr] = useState('');
    const generateSchoolRoll = async () => {

        if (schoolcode === "") {
            setErr("Kindly enter school code");
        } else {
            setErr("");
            const generateSchoolCode = await axios.get(`${API_BASE_URL}${API_END_POINTS.generateSchoolRoll}`, {
                params: {
                    sCode: schoolcode
                }
            });
            console.log("generateSchoolCode", generateSchoolCode)
            if (generateSchoolCode?.status === 200) {
                setErr("Roll number generated successfully");
                alert(`Total there are ${generateSchoolCode.data.data.affectedRows} records for which roll numbers are generated.`)
            }
        }


    };

    const generateAllRoll = async () => {

        if (schoolcode === "") {
            setErr("Kindly enter school code");
        } else {
            setErr("");
            const generateAllRoll = await axios.get(`${API_BASE_URL}${API_END_POINTS.generateAllRoll}`);
            console.log("generateAllRoll", generateAllRoll)
            if (generateAllRoll?.status === 200) {
                setErr("Roll number generated successfully");
                alert(`Total there are ${generateAllRoll.data.data.affectedRows} records for which roll numbers are generated.`)
            }
        }


    };

    return (
        <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-3">
                    {/* side bar will come here */}
                    <AdminSidebar isAdmin={isAdmin} />
                </div>
                <div className="col-lg-9 ">
                    <main className="content ">
                        <div className="container-fluid ps-md-4 ps-lg-5 pe-md-4 py-5">
                            <div className="section-title mb-4 text-muted">
                                <h6 className="font-bold ">Generate Roll Number</h6>
                                {/* <p>Schools have an option to upload students information by downloadable excel sheet OR through a form</p> */}
                            </div>
                        </div>
                    </main>
                    <div className="row">
                        <div className="col-sm ">
                            <div className="form-wrapper ">

                                {/* <input type="number" placeholder="Pin code" required="" /> */}
                                <label>School Code:</label>
                                <input

                                    type="text"
                                    placeholder=""
                                    name="scode"
                                    // required

                                    onChange={e => setSchoolCode(e.target.value)}
                                />
                            </div>
                        </div>

                    </div>
                    <div>
                        <h2 style={{ color: 'red' }}>{err}</h2>
                    </div>
                    <div className="btnmain" style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '30px'
                    }}>

                        <a> <button className={`btn btn-primary`} onClick={generateSchoolRoll}>
                            Generate School Roll No
                        </button></a>

                        <a> <button className={`btn btn-primary`} onClick={generateAllRoll}>
                            Generate All Roll No
                        </button></a>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default GenerateRoll