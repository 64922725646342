import dayjs from "dayjs";

export const formatDate = (dateValue) => {
  let dateStr = "";
  if (dateValue) {
    dateStr = dateValue.split("-");
    let date = dateStr.slice(0, 3).join("-");
    date = dayjs(date).format("DD-MM-YYYY");
    return `${date} ${dateStr.slice(-2).join("-")}`;
  }
  return "";
};

export const scrollToTop = () => {
  // ref.current?.scrollIntoView({ behavior: "smooth" });
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Add smooth scrolling behavior
  });
};
